import { forwardRef } from "react";
import countriesJSON from "../../countries.json";
import { Select } from "./Select";
import { renderCountryFlag } from "./helper";

const { countries = [] } = countriesJSON;

export const SelectCountry = forwardRef(
  function SelectCountryInternal(props, ref) {
    return (
      <Select
        {...props}
        ref={ref}
        options={countries.map((country) => ({
          value: country.code,
          children: country.name,
        }))}
        renderIcon={renderCountryFlag}
      />
    );
  },
);
