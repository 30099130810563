// A flexible heading element
const JsHeading = ({ headingLevel, children }) => {
  const Heading = headingLevel;
  return <Heading>{children}</Heading>;
};

// Reusable banner
export const Banner = ({
  headingText,
  description = "",
  headingLevel,
  className = "",
  onClick = () => {},
}) => (
  <div className={className} onClick={onClick}>
    <JsHeading headingLevel={headingLevel}>{headingText}</JsHeading>
    {typeof description === "string" ? <p>{description}</p> : description}
  </div>
);
