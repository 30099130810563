import { useEffect, useMemo, useState, useCallback } from "react";
import { ReactComponent as IcabbiIcon } from "../../assets/icons/icabbiIcon.svg";
import { ReactComponent as NavBurgerIcon } from "../../assets/icons/Sidebar/nav_burger.svg";
import { ReactComponent as NavBurgerOpenIcon } from "../../assets/icons/Sidebar/nav_burger_open.svg";

import Sidebar from "../../assets/icons/Sidebar";
import "./CompanySelector.scss";
import { useWindowSize } from "hooks/useWindowSize";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { CompanySelectorDesktopDrawer } from "./CompanySelectorDesktopDrawer";
import { CompanySelectorMobileDrawer } from "./CompanySelectorMobileDrawer";
import { useBreakpoint } from "hooks/useBreakpoint";

const CompanySelectorDriverOnly = ({
  isWallet = false,
  onMobilePickerOpen,
  isDrawerClosed = false,
  onCollapse,
}) => {
  const { fleets, selectedFleet, selectFleetId } = useSelectedFleet();

  const [open, setOpen] = useState(false);

  useWindowSize([open]);
  const screens = useBreakpoint();
  const xs = screens.xs;

  useEffect(() => {
    onMobilePickerOpen?.(open);
  }, [onMobilePickerOpen, open]);

  const toggle = () => setOpen((prevState) => !prevState);

  const [search, setSearch] = useState("");
  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  const filteredCompanies = useMemo(() => {
    if (search.length === 0) {
      return fleets;
    }

    return fleets.filter((company) => {
      return company.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search, fleets]);

  const handleCollapse = useCallback(
    (event) => {
      event.stopPropagation();
      onCollapse?.();
    },
    [onCollapse],
  );

  const navIcon = useMemo(() => {
    if (isWallet) {
      return <IcabbiIcon />;
    }

    if (isDrawerClosed) {
      return <NavBurgerIcon onClick={handleCollapse} />;
    } else {
      return <NavBurgerOpenIcon onClick={handleCollapse} />;
    }
  }, [handleCollapse, isDrawerClosed, isWallet]);

  return (
    <div className="company-selector">
      <div className="menu-trigger" onClick={toggle}>
        <span className="top-icon">
          {navIcon}
          <h3 className="selected-company-name">{selectedFleet?.name}</h3>
        </span>
        <Sidebar.ArrowDown />
      </div>

      {xs ? (
        <CompanySelectorMobileDrawer
          filteredCompanies={filteredCompanies}
          onSubmit={(id) => selectFleetId(id)}
          open={open}
          selectedCompany={selectedFleet}
          setOpen={setOpen}
        />
      ) : (
        <CompanySelectorDesktopDrawer
          filteredCompanies={filteredCompanies}
          onSearch={onSearch}
          onSubmit={(id) => selectFleetId(id)}
          open={open}
          selectedCompany={selectedFleet}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default CompanySelectorDriverOnly;
