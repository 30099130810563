import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

import { api } from "api";
import { generateListApiParams } from "helpers/helpers";
import { useSelectedFleet } from "hooks/useSelectedFleet";

import { BookingsList } from "./BookingsList/BookingsList";
import {
  Filters,
  driverBookingsPaymentMethodsInitialValues,
  driverBookingsPaymentStatusInitialValues,
} from "./Filters";

import styles from "./DriverBookings.module.scss";

let lasSevenDays = new Date().setDate(new Date().getDate() - 7);

const initialParams = (id) =>
  generateListApiParams(id, "driver_profile_id", true, 1, 25, {
    date_from: format(new Date(lasSevenDays), "yyyy-MM-dd"),
    date_to: format(new Date(), "yyyy-MM-dd"),
    payment_type: driverBookingsPaymentMethodsInitialValues,
    payout_status: driverBookingsPaymentStatusInitialValues,
    isWallet: true,
  });

export const BookingsListInternal = () => {
  const [params, setParams] = useState(null);
  const [dateRange, setDateRange] = useState([
    new Date(lasSevenDays),
    new Date(),
  ]);
  const dispatch = useDispatch();

  const { selectedDriverProfile } = useSelectedFleet();
  const selectedDriverProfileId = selectedDriverProfile?.id;

  const resetApiState = useCallback(
    () =>
      dispatch(
        api.util.updateQueryData("getBookingsList", undefined, (bookings) => {
          bookings.data = [];
        }),
      ),
    [dispatch],
  );

  const {
    data: bookings,
    isLoading,
    isUninitialized,
    isFetching,
  } = api.endpoints.getBookingsList.useQuery(params, {
    skip: !params || !selectedDriverProfile,
  });

  const loading = isLoading || isFetching;

  const pagination = bookings?.meta;

  const getNextBookings = () => {
    setParams((currentParams) => ({
      ...currentParams,
      cursor: pagination?.next_cursor,
    }));
  };

  const onDateRangeChange = useCallback(
    ([firstDate, lastDate]) => {
      setDateRange([firstDate, lastDate]);
      resetApiState();
      setParams((currentParams) => ({
        ...currentParams,
        date_from: format(firstDate, "yyyy-MM-dd"),
        date_to: format(lastDate, "yyyy-MM-dd"),
        page: 1,
      }));
    },
    [resetApiState],
  );

  const onPaymentTypeFilterChange = useCallback(
    (entries) => {
      resetApiState();
      setParams((currentParams) => ({
        ...currentParams,
        cursor: undefined,
        payment_type: entries,
      }));
    },
    [resetApiState],
  );

  const onPayoutStatusFilterChange = useCallback(
    (entries) => {
      resetApiState();
      setParams((currentParams) => ({
        ...currentParams,
        cursor: undefined,
        payout_status: entries,
      }));
    },
    [resetApiState],
  );

  const onSearch = useCallback(
    (e) => {
      if (e.target.value) {
        resetApiState();
        setParams((currentParams) => ({
          ...currentParams,
          external_id: isNaN(Number(e.target.value))
            ? e.target.value
            : Number(e.target.value),
          page: 1,
        }));
      } else {
        resetApiState();
        setParams(initialParams(selectedDriverProfileId));
      }
    },
    [selectedDriverProfileId, resetApiState],
  );

  const onClear = useCallback(
    (val) => {
      if (!val) {
        resetApiState();
        setParams(initialParams(selectedDriverProfileId));
      }
    },
    [selectedDriverProfileId, resetApiState],
  );

  useEffect(() => {
    if (selectedDriverProfileId) {
      setParams(initialParams(selectedDriverProfileId));
      resetApiState();
    } else {
      setParams(null);
    }
  }, [selectedDriverProfileId, resetApiState]);

  return (
    <div className={styles.driverBookings}>
      {selectedDriverProfileId && (
        <Filters
          dateRange={dateRange}
          onDateRangeChange={onDateRangeChange}
          onSearch={onSearch}
          onClear={onClear}
          paymentMethodValue={params?.payment_type}
          paymentStatusValue={params?.payout_status}
          onPaymentMethodChange={onPaymentTypeFilterChange}
          onPaymentStatusChange={onPayoutStatusFilterChange}
        />
      )}
      <div className={styles.driverBookingsWrapper}>
        <BookingsList
          id={selectedDriverProfileId}
          getNextBookings={getNextBookings}
          bookings={bookings}
          pagination={pagination}
          isLoading={loading}
          isUninitialized={isUninitialized}
          className={styles.bookingsListInternal}
        />
      </div>
    </div>
  );
};
