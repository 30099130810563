import { useTranslation } from "react-i18next";
import { Field } from "components/Field";
import { Input } from "components/Input";

export function ContactSection({ profile }) {
  const { t } = useTranslation();

  return (
    <>
      <Field label={t("field.name")}>
        <Input value={profile.name} readOnly />
      </Field>
      <Field label={t("field.phone")}>
        <Input value={profile.phone} readOnly />
      </Field>
      <Field label={t("field.email")}>
        <Input value={profile.email} readOnly />
      </Field>
    </>
  );
}
