import { useCallback, useMemo, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routes } from "routes";
import { api } from "api";

import { getContentOfRelevantItems } from "helpers/getContentOfRelevantItems";

import { DocumentType } from "pages/documents";

import { Form } from "components/Form";
import { Field } from "components/Field";
import { Input } from "components/Input";
import { PhoneNumberInput, normalizePhone } from "components/PhoneNumberInput";
import { Link } from "components/Link";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { errorSnack, successSnack } from "helpers/message";

export function CreateDriverProfileForm({
  initialValues,
  fleetId,
  fleetSlug,
  fleetCountryCode,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [internalErrors, setInternalErrors] = useState({});

  const [acceptLatestUserAgreementVersion] =
    api.endpoints.acceptLatestUserAgreementVersion.useMutation();

  const [postDriverApplication] =
    api.endpoints.postDriverApplication.useMutation();

  const onSubmit = ({ firstName, lastName, phone }) => {
    setInternalErrors({});

    acceptLatestUserAgreementVersion();

    postDriverApplication({
      fleet_id: fleetId,
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
    })
      .unwrap()
      .then(() => {
        successSnack({
          content: t("success.driverProfileCreated"),
        });
        navigate(
          routes.onboardingDocument.getURL({
            fleetSlug,
            documentTypeId: DocumentType.ADDRESS_PROOF,
          }),
        );
      })
      .catch((error) => {
        const errors = error?.data?.errors;

        if ("phone_number" in errors) {
          setInternalErrors((state) => ({
            ...state,
            phone: t("error.phoneInvalid"),
          }));
        }

        if ("fleet_id" in errors) {
          errorSnack({
            duration: 10,
            content: t("error.emailAlreadyRegisteredClickToSignIn"),
            onClick: () => {
              window.location.pathname = "";
            },
          });
        }
      });
  };

  const onSubmitInternal = useCallback(
    (form) => {
      onSubmit({
        ...form,
        phone: normalizePhone(form.phone),
      });
    },
    [onSubmit],
  );

  const renderForm = useCallback(
    ({ values, errors, onChange }) => {
      return (
        <>
          <div style={{ display: "flex", columnGap: 16, flexWrap: "wrap" }}>
            <div style={{ flex: "1 0 200px" }}>
              <Field
                label={t("field.firstName")}
                htmlFor="firstName"
                error={errors.firstName}
              >
                <Input
                  id="firstName"
                  name="firstName"
                  value={values.firstName}
                  onChange={onChange}
                />
              </Field>
            </div>
            <div style={{ flex: "1 0 200px" }}>
              <Field
                label={t("field.lastName")}
                htmlFor="lastName"
                error={errors.lastName}
              >
                <Input
                  id="lastName"
                  name="lastName"
                  value={values.lastName}
                  onChange={onChange}
                />
              </Field>
            </div>
          </div>

          <Field
            label={t("field.phone")}
            htmlFor="phone"
            error={errors.phone ?? internalErrors.phone}
          >
            <PhoneNumberInput
              id="phone"
              defaultCountry={fleetCountryCode}
              onChange={(value) =>
                onChange({
                  target: {
                    name: "phone",
                    value,
                  },
                })
              }
            />
          </Field>

          <Field error={errors.acceptAgreement}>
            <Checkbox
              name="acceptAgreement"
              checked={values.acceptAgreement}
              onChange={onChange}
              className="custom-checkbox"
            >
              <Trans
                i18nKey="modalTermsNotAccepted.iReadAndAcceptTerms"
                components={{
                  termslink: (
                    <Link
                      to={routes.termsAndConditions.getURL()}
                      target="_blank"
                    />
                  ),
                }}
              />
            </Checkbox>
          </Field>

          <Button
            block
            type="submit"
            variant="primary"
            shape="round"
            fontWeight="bold"
          >
            {t("action.next")}
          </Button>
        </>
      );
    },
    [t, internalErrors],
  );

  const validationRules = useMemo(() => {
    return getContentOfRelevantItems([
      {
        condition: true,
        content: {
          field: "firstName",
          isValid: (value) => typeof value === "string" && value.length > 0,
          error: t("error.firstNameRequired"),
        },
      },
      {
        condition: true,
        content: {
          field: "lastName",
          isValid: (value) => typeof value === "string" && value.length > 0,
          error: t("error.lastNameRequired"),
        },
      },
      {
        condition: true,
        content: {
          field: "phone",
          isValid: (value) => typeof value === "string" && value.length > 0,
          error: t("error.phoneRequired"),
        },
      },
      {
        condition: true,
        content: {
          field: "phone",
          isValid: (value) => typeof value === "string" && value.length > 6,
          error: t("error.phoneInvalid"),
        },
      },
      {
        condition: true,
        content: {
          field: "acceptAgreement",
          isValid: (value) => value,
          error: t("error.agreementRequired"),
        },
      },
    ]);
  }, [t]);

  return (
    <Form
      initialValues={initialValues}
      validationRules={validationRules}
      onSubmit={onSubmitInternal}
    >
      {renderForm}
    </Form>
  );
}
