import { Col, Row, Tooltip } from "antd";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";

import { twoDecimals } from "helpers/twoDecimal";
import { statusColor } from "pagesLegacy/FleetView/BookingsList/Table/constants";
import { DRIVER_PROFILE_ID_QUERY_PARAM } from "../constants";

import { ReactComponent as AccountIcon } from "assets/icons/Wallet/account.svg";
import { ReactComponent as Card } from "assets/icons/Wallet/card.svg";
import { ReactComponent as Cash } from "assets/icons/Wallet/cash.svg";

import styles from "./BookingItem.module.scss";
import { calculateDriverCommissionValue } from "helpers/calculateDriverCommissionValue";
import { PAYMENT_METHOD } from "constants/index";

export const BookingItem = ({ booking, currency }) => {
  const { t } = useTranslation();
  const { releaseDeductDriverCommission } = useFlags();

  const navigate = useNavigate();
  const {
    total_cost,
    id,
    booking_id,
    pick_up,
    payout_status,
    payment_type,
    updated_at,
    is_driverpay_booking,
    driver_commission,
  } = booking;

  const totalCostDividedBy100 = total_cost / 100;
  const commissionValue = calculateDriverCommissionValue(
    totalCostDividedBy100,
    driver_commission,
    releaseDeductDriverCommission,
  );

  const onSelect = () => {
    const params = new URLSearchParams();
    params.append(
      DRIVER_PROFILE_ID_QUERY_PARAM,
      booking[DRIVER_PROFILE_ID_QUERY_PARAM],
    );
    navigate(`${id}?${params.toString()}`);
  };

  return (
    <Row gutter={[24, 24]} onClick={onSelect}>
      <Col xs={4} sm={2} md={2} xl={2}>
        {payment_type === PAYMENT_METHOD.INVOICE ? (
          <AccountIcon data-testid={payment_type} />
        ) : payment_type === PAYMENT_METHOD.CASH ? (
          <Cash data-testid={payment_type} />
        ) : (
          <Card data-testid={payment_type} />
        )}
      </Col>
      <Col xs={13} sm={17} md={17} xl={17}>
        <h2>{pick_up}</h2>
        {/* TODO: time of booking completion */}
        <div className={styles.secondary}>
          <div>{booking_id}</div>
          <div>{format(new Date(updated_at), "hh:mm a")}</div>
          <div>
            <Tooltip
              title={
                is_driverpay_booking
                  ? t("tooltip.serviceEnabledForBooking")
                  : t("tooltip.serviceDisabledForBooking")
              }
              data-testid="tooltip"
            >
              <div
                className={clsx(styles.driverpayIndicator, {
                  [styles.enabled]: is_driverpay_booking,
                  [styles.disabled]: !is_driverpay_booking,
                })}
              />
            </Tooltip>
          </div>
        </div>
      </Col>
      <Col className={styles.bookingStatusSection} xs={6} sm={4} md={4} xl={4}>
        <h2>
          {currency}
          {twoDecimals(totalCostDividedBy100 - commissionValue)}
        </h2>
        <div
          style={{
            color: statusColor[payout_status.toLowerCase()],
            border: `1px solid ${statusColor[payout_status.toLowerCase()]}`,
          }}
          className={styles.bookingStatus}
          data-testid={`booking-${payout_status}`}
        >
          {payout_status.toUpperCase()}
        </div>
      </Col>
    </Row>
  );
};
