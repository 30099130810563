import { useTranslation } from "react-i18next";

import { SelectedCompanyFeatureFlagAlternativeImplementation } from "components/SelectedCompanyFeatureFlagRequired";
import {
  GeneralLayoutContent,
  GeneralLayoutHeader,
} from "components/GeneralLayout";

import { HomePageInternal } from "./HomePageInternal";

import { ReactComponent as HomeIcon } from "assets/icons/Wallet/home.svg";

export function HomePageWithGates() {
  const { t } = useTranslation();

  return (
    <SelectedCompanyFeatureFlagAlternativeImplementation>
      <GeneralLayoutContent
        header={
          <GeneralLayoutHeader title={t("title.home")} icon={<HomeIcon />} />
        }
      >
        <HomePageInternal />
      </GeneralLayoutContent>
    </SelectedCompanyFeatureFlagAlternativeImplementation>
  );
}
