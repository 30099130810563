import React, { useEffect, useRef, useState } from "react";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";

import { CloseOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { Button } from "components/Button";
import { Radio } from "components/RadioGroup";

import styles from "./CompanySelector.module.scss";

export const CompanySelectorMobileDrawer = ({
  open,
  setOpen,
  filteredCompanies,
  selectedCompany,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const companySelectorMobileListRef = useRef(null);
  const hasCompanySelectorMobileListOverflow =
    companySelectorMobileListRef.current === null
      ? false
      : companySelectorMobileListRef.current?.scrollHeight >
        companySelectorMobileListRef.current?.clientHeight;

  const [locallySelectedCompany, setLocallySelectedCompany] =
    useState(selectedCompany);

  useEffect(() => {
    setLocallySelectedCompany(selectedCompany);
  }, [selectedCompany]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit(locallySelectedCompany?.id);
    handleClose();
  };

  return (
    <Drawer
      closable={false}
      placement="bottom"
      visible={open}
      height="auto"
      onClose={() => {
        setOpen((prevState) => !prevState);
      }}
      className={clsx(
        "companies-selector-mobile-view-drawer",
        hasCompanySelectorMobileListOverflow
          ? "company-selector-mobile-overflow"
          : null,
      )}
    >
      <div className="company-selector-mobile">
        <div className="company-selector-mobile__header">
          <div className="company-selector-mobile__title">
            {t("componentSelectFleet.selectAccount")}
          </div>
          <CloseOutlined className="close-btn" onClick={() => setOpen(false)} />
        </div>
        <div
          ref={companySelectorMobileListRef}
          className="company-selector-mobile-list"
        >
          {filteredCompanies.map((company, index) => (
            <div
              onClick={() => setLocallySelectedCompany(company)}
              key={company?.id}
            >
              <Radio checked={locallySelectedCompany?.id === company?.id} />
              <p>{company.name}</p>
            </div>
          ))}
        </div>
        <Button
          onClick={handleSubmit}
          shape="round"
          variant="primary"
          className={styles.saveButton}
        >
          {t("action.save")}
        </Button>
      </div>
    </Drawer>
  );
};
