import { useCallback } from "react";
import { Layout } from "antd";

import { useSelectedFleet } from "hooks/useSelectedFleet";
import { getIsStripeCompletedByDriverProfile } from "helpers/getIsStripeCompletedByDriverProfile";

import { StripeConfirmationModal } from "components/StripeConfirmationModal";

import { DriverSidebar } from "./DriverSidebar";

import "./GeneralLayout.scss";

export function GeneralLayout({ children }) {
  const { fleets, selectedFleet, selectedDriverProfile, selectFleetId } =
    useSelectedFleet();

  const isStripeConfirmationModalVisible = (() => {
    if (!selectedDriverProfile) {
      return false;
    }

    const isStripeCompleted = getIsStripeCompletedByDriverProfile(
      selectedDriverProfile,
    );

    const isDriverPayEnabled = Boolean(selectedFleet?.enable_driver_pay);
    return isDriverPayEnabled && !isStripeCompleted;
  })();

  const handleConfirmationModalFleetChange = useCallback(
    (fleetId) => {
      if (fleetId) {
        selectFleetId(fleetId);
      }
    },
    [selectFleetId],
  );

  return (
    <>
      <Layout className="fleet-layout-wrapper">
        <div style={{ flexGrow: 1 }}>{children}</div>
        <DriverSidebar />
      </Layout>

      {selectedFleet && (
        <StripeConfirmationModal
          visible={isStripeConfirmationModalVisible}
          onFleetChange={handleConfirmationModalFleetChange}
          selectedFleet={selectedFleet}
          allFleets={fleets}
          driverProfileId={selectedDriverProfile?.id}
          driverProfile={selectedDriverProfile}
        />
      )}
    </>
  );
}
