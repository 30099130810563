import { errorSnack, infoSnack, loadingSnack, successSnack, warningSnack } from "./message";

const DEFAULT_KEY = "windowMessages";
const DEFAULT_DURATION = 3;
const DEFAULT_SUCCESS_MSG = "Saved!";
const DEFAULT_LOADING_MSG = "Saving...";
const DEFAULT_ERROR_MSG = "Something went wrong.";
const DEFAULT_INFO_MSG = "This is an info message";

export const showLoadingMsg = (params) => {
  const { content = DEFAULT_LOADING_MSG, key = DEFAULT_KEY } = params || {};
  loadingSnack({ content, key });
};

export const showInfoMsg = (params) => {
  const {
    content = DEFAULT_INFO_MSG,
    key = DEFAULT_KEY,
    duration = DEFAULT_DURATION,
    className = "",
  } = params || {};
  infoSnack({ content, key, duration, className });
};

export const showSuccessMsg = (params) => {
  const {
    content = DEFAULT_SUCCESS_MSG,
    key = DEFAULT_KEY,
    duration = DEFAULT_DURATION,
    className = "",
  } = params || {};
  successSnack({ content, key, duration, className });
};

export const showErrorMsg = (params) => {
  const {
    content = DEFAULT_ERROR_MSG,
    key = DEFAULT_KEY,
    duration = DEFAULT_DURATION,
    className = "",
  } = params || {};
  errorSnack({ content, key, duration, className });
};

export const showWarningMsg = (params) => {
  const {
    content = DEFAULT_ERROR_MSG,
    key = DEFAULT_KEY,
    duration = DEFAULT_DURATION,
    className = "",
  } = params || {};

  warningSnack({ content, key, duration, className });
};
