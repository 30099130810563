import { MenuToggle } from "components/MenuToggle/MenuToggle";
import { DashboardPicker } from "./components/DashboardPicker/DashboardPicker";
import { DashboardItem } from "./components/DashboardItem/DashboardItem";
import { PageHeader } from "components/PageHeader/PageHeader";
import { RedirectToCompany, useQueryCompanies } from "hooks/useQueryCompanies";
import { api } from "api";
import { LoadingComp } from "components/LoadingComp";
import { Empty } from "components/Empty";
import SideBarIcons from "assets/icons/Sidebar";
import { CustomDropdown } from "pagesLegacy/components/CustomDropdown";
import styles from "./Dashboard.module.scss";
import { dashboardPickerOptions } from "./components/DashboardPicker/DashboardPickerOptions";
import { useState } from "react";
import { formatCurrency } from "helpers/formatCurrency";

export const Dashboard = () => {
  const { currentCompany: selectedCompany } = useQueryCompanies({
    redirectTo: RedirectToCompany.ADMIN,
  });
  const [selectedDate, setSelectedDate] = useState(
    dashboardPickerOptions[0].dateRange,
  );

  const { date_to, date_from } = selectedDate;

  const {
    data: stats,
    isLoading,
    isError,
  } = api.endpoints.getDashboard.useQuery(
    {
      fleetId: selectedCompany?.id,
      date_from,
      date_to,
    },
    { skip: !selectedCompany?.id },
  );

  const transactionsLink = `/console/transactions?firstDate=${date_from}&lastDate=${date_to}`;
  const reportsLink = `/console/reports?firstDate=${date_from}&lastDate=${date_to}`;

  const payouts = formatCurrency(
    (stats?.data?.total_amount ?? 0) / 100,
    selectedCompany?.currency,
  );

  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboard__header}>
        <MenuToggle />

        <PageHeader
          title={"Dashboard"}
          icon={<SideBarIcons.DashboardIcon />}
        ></PageHeader>
        <div className={styles.dashboard__dropdown}>
          <CustomDropdown />
        </div>
        <DashboardPicker
          wrapperClassName={styles.dashboard__picker}
          onOptionSelect={(selectedDateRange) =>
            setSelectedDate(selectedDateRange)
          }
        />
      </div>

      {isLoading && (
        <div className={styles.dashboard__inner}>
          <LoadingComp loading={isLoading} />
        </div>
      )}

      {!isLoading && stats && stats.data && (
        <>
          <DashboardItem
            testId="payouts"
            amount={payouts}
            title="Payouts"
            className={styles.dashboard__topStat}
            link={transactionsLink}
            linkText="View all transactions"
          />

          <div className={styles.dashboard__stats}>
            <DashboardItem
              testId="payoutsCount"
              className={styles.dashboard__stat}
              amount={`${stats?.data?.total_payouts.toLocaleString()}`}
              title="Payout count"
              subtitle="Total payouts"
              link={transactionsLink}
              linkText="View all transactions"
            />
            <DashboardItem
              testId="payoutsAccounts"
              className={styles.dashboard__stat}
              amount={`${stats?.data?.payout_accounts.toLocaleString()}`}
              title="Payout accounts"
              subtitle="Drivers paid"
              link="/console/drivers"
              linkText="View all drivers"
            />
            <DashboardItem
              testId="instantPayouts"
              className={styles.dashboard__stat}
              amount={`${stats?.data?.total_instant_payouts.toLocaleString()}`}
              title="Instant payouts"
              subtitle="Total withdrawals"
              link={reportsLink}
              linkText="View all payout reports"
            />
          </div>
        </>
      )}

      {isError && (
        <div className={styles.dashboard__empty}>
          <Empty title={"data"} />
        </div>
      )}
    </div>
  );
};
