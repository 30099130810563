import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { DateRangeInput } from "components/DateRangeInput";
import { CustomDropdown } from "pagesLegacy/components/CustomDropdown";
import { FilterDropdown } from "components/FilterDropdown";

import { ReactComponent as ClockIcon } from "assets/icons/Bookings/clock.svg";
import { ReactComponent as DollarIcon } from "assets/icons/Bookings/dollarSign.svg";

import {
  bookingActionOptions,
  paymentStatusOptions,
  paymentStatusOptionsInitialValue,
  paymentTypeOptions,
  paymentTypeOptionsInitialValue,
} from "./DropdownOptions";

export const Filters = ({
  date,
  onSearch,
  onDateChange,
  isTabletOrMobile,
  onItemSelect,
  onClearSearch,
  searchInputValue,
  onPaymentTypeFilterChange,
  onPayoutStatusFilterChange,
  paymentTypeFilterValue,
  payoutStatusFilterValue,
}) => (
  <>
    <div className="filters">
      <Input
        addonBefore={<SearchOutlined />}
        placeholder="Driver ID or Booking ID"
        onPressEnter={onSearch}
        value={searchInputValue}
        onChange={({ target }) => onClearSearch(target.value)}
        allowClear
      />
      <DateRangeInput value={date} onChange={onDateChange} />
      <FilterDropdown
        Icon={DollarIcon}
        options={paymentTypeOptions}
        initialVal={paymentTypeOptionsInitialValue}
        btnLabel="Payment type"
        btnLabelMobile="Payment type"
        className="payment-type"
        name="payment_type"
        onChange={onPaymentTypeFilterChange}
        filterValue={paymentTypeFilterValue}
      />
      <FilterDropdown
        Icon={ClockIcon}
        options={paymentStatusOptions}
        initialVal={paymentStatusOptionsInitialValue}
        btnLabel="Payout status"
        btnLabelMobile="Payout status"
        name="payout_status"
        onChange={onPayoutStatusFilterChange}
        filterValue={payoutStatusFilterValue}
      />
      {!isTabletOrMobile && (
        <CustomDropdown
          options={bookingActionOptions}
          onItemSelect={onItemSelect}
        />
      )}
    </div>
  </>
);
