import { forwardRef, useState, useEffect } from "react";
import clsx from "clsx";
import { Select as AntdSelect } from "antd";

import styles from "./Select.module.scss";

/**
 * This component knows what to provide to the render-props,
 * thus it can be used in both controlled and uncontrolled ways.
 */
export const Select = forwardRef(function SelectInternal(
  {
    renderIcon,
    options = [],
    size = "large",
    value: externalValue,
    defaultValue: externalDefaultValue,
    onChange: externalOnChange,
    popupClassName = "",
    ...otherProps
  },
  ref,
) {
  const isControlled = typeof externalOnChange === "function";

  const [value, setValue] = useState(function getInitialValue() {
    if (isControlled) {
      return externalValue;
    }

    if (externalDefaultValue !== undefined) {
      return externalDefaultValue;
    }

    return options[0]?.value;
  });

  useEffect(() => {
    if (isControlled) {
      setValue(externalValue);
    }
  }, [externalValue, isControlled]);

  const onChange = (value) => {
    setValue(value);
    if (isControlled) {
      externalOnChange(value);
    }
  };

  const hasIcon = typeof renderIcon === "function";

  return (
    <div className={styles.wrapper}>
      <AntdSelect
        className={clsx(styles.customSelect, { [styles.hasIcon]: hasIcon })}
        size={size}
        ref={ref}
        value={value}
        popupClassName={clsx(styles.customDropdown, popupClassName)}
        onChange={onChange}
        {...otherProps}
      >
        {options.map((optionProps) => (
          <AntdSelect.Option key={optionProps.value} {...optionProps}>
            {hasIcon && (
              <div className={styles.icon}>
                {renderIcon({ value: optionProps.value })}
              </div>
            )}
            {optionProps.children}
          </AntdSelect.Option>
        ))}
      </AntdSelect>
    </div>
  );
});
