import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Popover } from "antd";

import countries from "countries.json";
import ReactCountryFlag from "react-country-flag";

import { ReactComponent as ArrowDownIcon } from "assets/icons/Sidebar/arrowDown.svg";
import { InputError } from "../FormInput/InputError";
import errorStyles from "../FormInput/FormInput.module.scss";

import styles from "./PhoneNumberInput.module.scss";
import { Input } from "components/Input";

const allCountries = countries.countries;

export const PhoneNumberInput = ({
  onChange,
  defaultCountry,
  value,
  defaultValue,
  id,
  onBlur,
  error,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);

  const [phoneNumber, setPhoneNumber] = useState(String(defaultValue || ""));
  const [countrySelectorOpen, setCountrySelectorOpen] = useState(false);
  const [countriesList, setCountriesList] = useState(allCountries);

  useEffect(() => {
    if (value) {
      const stringifiedValue = String(value);
      const countryDialCode = stringifiedValue.slice(
        0,
        stringifiedValue.indexOf("-"),
      );
      const number = stringifiedValue.slice(stringifiedValue.indexOf("-") + 1);

      setPhoneNumber(number);
      setSelectedCountry(
        countries.countries.find(
          ({ dial_code }) => dial_code === countryDialCode,
        ),
      );
    }
  }, [value]);

  const openCountrySelect = () => {
    setCountrySelectorOpen(true);
  };

  const closeCountrySelect = () => {
    setCountrySelectorOpen(false);
  };

  const onCountrySelect = useCallback(
    (country, event) => {
      event?.stopPropagation();
      setSelectedCountry(country);
      onChange?.(`${country.dial_code}-${phoneNumber}`);
      closeCountrySelect();
    },
    [onChange, phoneNumber],
  );

  const handlePlusSignInput = (value) => {
    const foundCountries = allCountries.filter(({ dial_code }) =>
      value.startsWith(dial_code),
    );

    if (foundCountries.length > 1) {
      openCountrySelect();
      return setCountriesList(foundCountries);
    } else if (foundCountries.length === 1) {
      onCountrySelect(foundCountries[0]);
      return setCountriesList(allCountries);
    }
  };

  const handleCountriesListState = (value, hasWrittenPlusSign) => {
    if (value.length === 0) {
      return setCountriesList(allCountries);
    }

    if (hasWrittenPlusSign) {
      handlePlusSignInput(value);
    }
  };

  const handleInputChange = (value) => {
    const normalizedValue = value.replace("+", "").replace(/\D+/g, "");
    const hasWrittenPlusSign = value.startsWith("+");
    const finalValue = hasWrittenPlusSign
      ? `+${normalizedValue}`
      : normalizedValue;

    handleCountriesListState(value, hasWrittenPlusSign);

    setPhoneNumber(finalValue);
    onChange?.(`${selectedCountry.dial_code}-${normalizedValue}`);
  };

  const countrySelectorValue = countriesList.find(({ code }) => {
    return code === selectedCountry.code;
  });

  const handleBlur = (event) => {
    if (phoneNumber.startsWith("+")) {
      if (phoneNumber.startsWith(selectedCountry.dial_code)) {
        handleInputChange(phoneNumber.replace(selectedCountry.dial_code, ""));
      }
    }
    onBlur?.(event);
  };

  const popoverContent = useMemo(() => {
    return (
      <div className={styles.popoverWrapper}>
        {countriesList.map((country) => (
          <div
            onClick={(event) => onCountrySelect(country, event)}
            key={country.code}
            className={styles.countrySelectorListElement}
          >
            <ReactCountryFlag countryCode={country.code} svg />
            <p>
              {country.code} ({country.dial_code})
            </p>
          </div>
        ))}
      </div>
    );
  }, [onCountrySelect, countriesList]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.countrySelectorWrapper}>
          <div
            data-testid="phone-number-country-selector"
            onClick={openCountrySelect}
            className={styles.countrySelector}
          >
            <ReactCountryFlag countryCode={countrySelectorValue?.code} svg />
            <p>
              {countrySelectorValue?.code} {countrySelectorValue?.dial_code}
            </p>
            <ArrowDownIcon />
          </div>
          <Popover
            content={popoverContent}
            title=""
            trigger="click"
            open={countrySelectorOpen}
            onOpenChange={setCountrySelectorOpen}
            getPopupContainer={(trigger) => trigger.parentNode}
            placement="right"
          ></Popover>
        </div>
        <Input
          id={id}
          type="tel"
          className={styles.numberInput}
          value={phoneNumber}
          onChange={({ target }) => handleInputChange(target.value)}
          onBlur={handleBlur}
        />
      </div>
      {error && <InputError className={errorStyles.errorText} text={error} />}
    </div>
  );
};

export function normalizePhone(phone) {
  return phone.replace("-", "");
}
