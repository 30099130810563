import { forwardRef } from "react";
import clsx from "clsx";
import { Input as AntdInput } from "antd";
import styles from "./Input.module.scss";

export const Input = forwardRef(function InputInternal(
  { invalid, className, ...otherProps },
  ref,
) {
  return (
    <AntdInput
      {...otherProps}
      className={clsx(styles.abstractInput, className, {
        [styles.invalid]: invalid,
        [styles.readonly]: otherProps.readOnly,
      })}
      ref={ref}
    />
  );
});
