import { useSearchParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { api } from "api";

import { WaveLayout } from "components/WaveLayout";
import { Container } from "components/Container";
import { Title } from "components/Title";
import { Description } from "components/Description";
import { Spacer } from "components/Spacer";

import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { prepareErrorMessage } from "helpers/prepareErrorMessage";
import { destroySnack, errorSnack, loadingSnack, successSnack } from "helpers/message";

export function EmailSentPage() {
  const { t } = useTranslation();
  const [urlParams] = useSearchParams();
  const [forgetPassword] = api.endpoints.forgetPassword.useMutation();

  const email = urlParams.get("email") || "";

  function onResendClick(event) {
    event.preventDefault();

    loadingSnack({
      content: t("processing.emailSending"),
      duration: 0,
      key: "sendingEmail",
    });

    forgetPassword({
      email,
    })
      .unwrap()
      .then(() => {
        successSnack({
          content: t("success.resetPasswordLinkSent"),
          duration: 5,
        });
      })
      .catch(({ data }) => {
        const content =
          prepareErrorMessage(data) ?? t("error.somethingWentWrong");
        errorSnack({
          content,
          duration: 5,
        });
      })
      .finally(() => {
        destroySnack("sendingEmail");
      });
  }

  return (
    <WaveLayout>
      <Container width="small">
        <EmailIcon />
        <Spacer />

        <Title>{t("pageResetPasswordEmailSent.accessIsEnRoute")}</Title>
        <Description>
          <Trans
            i18nKey="pageResetPasswordEmailSent.checkEmail"
            components={{
              button: (
                <a
                  href="#"
                  onClick={onResendClick}
                  style={{
                    color: "#47d061",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                />
              ),
            }}
          />
        </Description>
      </Container>
    </WaveLayout>
  );
}
