import { useSelectedFleet } from "hooks/useSelectedFleet";

import { PageHeader } from "components/PageHeader/PageHeader";

import { BurgerSidebarTriggerButton } from "../BurgerSidebarTriggerButton";

import { useIsMobile } from "../useIsMobile";
import { StripeAlertBanner } from "../StripeAlertBanner";

import "./GeneralLayoutHeader.scss";
import styles from "./GeneralLayoutHeader.module.scss";

export function GeneralLayoutHeader({ title, icon }) {
  const isMobile = useIsMobile();

  const { selectedDriverProfile, selectedFleet } = useSelectedFleet();

  const isDriverPayEnabled = Boolean(selectedFleet?.enable_driver_pay);

  const isStripeConnected =
    selectedDriverProfile?.stripe_details_submitted &&
    selectedDriverProfile?.stripe_payouts_enabled &&
    isDriverPayEnabled;

  const showStripeAlertBanner = Boolean(selectedDriverProfile);
  const stripeAlertBannerElement = showStripeAlertBanner ? (
    <StripeAlertBanner
      connected={isStripeConnected}
      enabled={isDriverPayEnabled}
    />
  ) : null;

  if (isMobile) {
    return (
      <div className={styles.activeMenuIndicator}>
        <BurgerSidebarTriggerButton />
        <h1>{title}</h1>
        <span className={styles.stripeStatusMobile}>{stripeAlertBannerElement}</span>
      </div>
    );
  }

  return (
    <div className={styles.walletLayoutHeader}>
      <PageHeader title={title} icon={icon}>
        {stripeAlertBannerElement}
      </PageHeader>
    </div>
  );
}
