import { Tag } from "antd";
import { useTranslation } from "react-i18next";

import { ReactComponent as PoweredByStripeIcon } from "assets/icons/stripe-powered-icon.svg";

import styles from "./StripeAlertBanner.module.scss";

export function StripeAlertBanner({ connected, enabled }) {
  const { t } = useTranslation();

  return (
    <div className={styles.stripeAlertBanner}>
      <Tag color={connected ? "success" : "error"}>
        {!enabled
          ? t("componentStripeBadge.disabled")
          : connected
            ? t("componentStripeBadge.enabled")
            : t("componentStripeBadge.notFinished")}
      </Tag>
      <PoweredByStripeIcon />
    </div>
  );
}
