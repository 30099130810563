import { useSelector } from "react-redux";
import { usePredefinedVehicleDocumentTypes } from "../../documents";

export function useApplicationCompleted() {
  const predefinedVehicleDocumentTypesToSubmit =
    usePredefinedVehicleDocumentTypes();

  const { vehicleApplicationFormData } = useSelector(
    (state) => state.addNewVehicleslice,
  );

  const areVehicleDocumentsReady = predefinedVehicleDocumentTypesToSubmit.every(
    ({ id }) => vehicleApplicationFormData[id],
  );

  return areVehicleDocumentsReady;
}
