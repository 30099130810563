import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { Modal } from "antd";
import { routes } from "routes";
import { api } from "api";

import { useSelectedFleet } from "hooks/useSelectedFleet";
import { VehicleDetailsForm } from "../ReviewNewVehiclePage/VehicleDetailsForm";
import { encodeValue } from "pages/documents/converters";
import { Button } from "components/Button";
import { LoadingComp } from "components/LoadingComp";
import {
  clearVehicleApplicationFormData,
  setNewVehicleRegistratioinNumber,
  setVehicleApplicationFormData,
  setVehicleApplicationId,
} from "store/slices/addNewVehicleSlice";

import { WrapperScreen } from "../WrapperScreen";
import { SubmitApplicationPage } from "../SubmitApplication";
import {
  DocumentType,
  FieldType,
  useQueryCustomDocumentTypes,
  useQueryDocumentTypeById,
  ValidationWrapper,
} from "../../documents";
import { CreateDocumentForm } from "./CreateDocumentForm";
import { prepareErrorMessage } from "helpers/prepareErrorMessage";
import { destroySnack, errorSnack, loadingSnack, successSnack } from "helpers/message";
import styles from "./CreateNewVehiclePage.module.scss";

const initialVehicleDetailsDocument = {
  registration: "",
  area: "",
  year: "",
  make: "",
  model: "",
  colour: "",
  emissions: "",
  nctExpiry: "",
  roadTaxExpiry: "",
};

export function CreateNewVehiclePage() {
  const ldClient = useLDClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { releaseAddNewVehicle } = useFlags();
  const { documentTypeId } = useParams();
  const { t } = useTranslation();
  const { registrationNumberToReplace, vehicleApplicationFormData } =
    useSelector((state) => state.addNewVehicleslice);

  const { selectedFleetId: fleetId, selectedDriverProfile } =
    useSelectedFleet();

  const [
    createVehicleApplications,
    {
      error: creatingError,
      isLoading: isCreating,
      isSuccess: isCreatingSucceed,
      isError: isCreatingFailed,
      reset,
    },
  ] = api.endpoints.createVehicleApplications.useMutation();

  const [postManualVehicleLookUpWithActiveFeatures, { isLoading: isSaving }] =
    api.endpoints.postManualVehicleLookUpWithActiveFeatures.useMutation();

  const [getDvla] = api.endpoints.getDvla.useMutation();

  useEffect(() => {
    if (!releaseAddNewVehicle) {
      navigate(routes.documents.getURL());
    }
  }, [releaseAddNewVehicle]);

  useEffect(() => {
    if (isCreatingFailed) {
      const errorMessage = prepareErrorMessage(creatingError);

      if (errorMessage === undefined) {
        errorSnack({
          content: t("error.documentNotSaved"),
          duration: 8,
        });
      } else {
        errorSnack({
          content: t("error.documentNotSavedWithReason", {
            reason: errorMessage,
          }),
          duration: 8,
        });
      }
    }

    if (isCreatingSucceed) {
      successSnack({
        content: t("success.documentSaved"),
      });

      navigate(nextPage);
    }

    if (isCreatingSucceed || isCreatingFailed) {
      reset();
      destroySnack("saving_document");
    }
  }, [isCreatingSucceed, isCreatingFailed, navigate, reset, creatingError, t]);

  useEffect(() => {
    if (isSaving) {
      loadingSnack({
        content: t("processing.sendingVehicleDetails"),
        duration: 0,
        key: "vehicle_details_loading",
      });
    } else {
      destroySnack("vehicle_details_loading");
    }
  }, [isSaving, t]);

  useEffect(() => {
    const context = ldClient.getContext();
    const nextContextKey = String(fleetId);

    if (fleetId !== null && context?.key !== nextContextKey) {
      ldClient.identify({
        kind: "fleet",
        key: nextContextKey,
      });
    }
  }, [fleetId, ldClient]);

  const {
    predefinedVehicleDocumentTypes,
    customVehicleApplicationDocumentTypes,
    addNewVehicleSteps,
    isLoading: areDocumentTypesDetailLoading,
  } = useQueryCustomDocumentTypes({
    currentCompanyId: fleetId,
  });

  const {
    data: currentDocumentTypeDetails,
    isLoading: areCurrentDocumentTypeDetailsLoading,
  } = useQueryDocumentTypeById({ documentTypeId, currentCompanyId: fleetId });
  const currentStepIndex = addNewVehicleSteps.indexOf(documentTypeId);

  const nextPage =
    currentStepIndex < 0 ||
    currentStepIndex >=
      [
        ...predefinedVehicleDocumentTypes,
        ...customVehicleApplicationDocumentTypes,
      ].length
      ? routes.addNewVehicle.getURL({ documentTypeId })
      : routes.addNewVehicle.getURL({
          documentTypeId: addNewVehicleSteps[currentStepIndex + 1] || "submit",
        });

  const { data: fleetPublicData } =
    api.endpoints.getFleetPublicInfoById.useQuery(
      {
        fleetId,
      },
      { skip: fleetId === undefined || fleetId === null },
    );

  const vehicleLookupService =
    fleetPublicData?.data?.vehicle_lookup_service ?? "manual";

  const onReturnBack = () => {
    Modal.confirm({
      title: t("pageAddNewVehicle.returnWithoutSaving"),
      icon: null,
      content: "",
      okText: t("optionGeneral.yes"),
      cancelText: t("optionGeneral.no"),
      className: "custom-vehicle-replacement-promp",
      onOk: () => navigate(routes.documents.getURL()),
    });
    dispatch(clearVehicleApplicationFormData("all"));
    dispatch(setVehicleApplicationId(null));
    dispatch(setNewVehicleRegistratioinNumber(null));
  };

  if (
    areCurrentDocumentTypeDetailsLoading ||
    isCreating ||
    areDocumentTypesDetailLoading
  ) {
    return <LoadingComp loading fullScreen />;
  }

  if (documentTypeId === "submit") {
    return (
      <WrapperScreen breadcrumbs key={documentTypeId}>
        <SubmitApplicationPage />
      </WrapperScreen>
    );
  }

  const isVehicleDetailsExist = Boolean(
    vehicleApplicationFormData[DocumentType.VEHICLE_DETAILS],
  );
  if (documentTypeId === DocumentType.VEHICLE_DETAILS) {
    const buttons = isVehicleDetailsExist
      ? [
          {
            block: true,
            variant: "default",
            importance: "error",
            shape: "round",
            fontWeight: "bold",
            onClick: () => {
              dispatch(
                clearVehicleApplicationFormData(DocumentType.VEHICLE_DETAILS),
              );
            },
            children: t("action.clear"),
          },
          {
            block: true,
            variant: "primary",
            shape: "round",
            fontWeight: "bold",
            to: nextPage,
            children: t("action.next"),
          },
        ]
      : [
          {
            block: true,
            variant: "primary",
            shape: "round",
            fontWeight: "bold",
            onClick: "onSave",
            loading: isCreating,
            disabled: isCreating,
            children: t("action.saveAndNext"),
          },
        ];

    return (
      <WrapperScreen breadcrumbs key={documentTypeId}>
        <ValidationWrapper>
          <VehicleDetailsForm
            isEditPage={!isVehicleDetailsExist}
            vehicleLookupService={vehicleLookupService}
            document={
              isVehicleDetailsExist
                ? vehicleApplicationFormData[DocumentType.VEHICLE_DETAILS]
                : initialVehicleDetailsDocument
            }
            buttons={buttons}
            onSave={(form) => {
              createVehicleApplications({
                driverId: selectedDriverProfile?.id,
                data: {
                  vehicle_registration: form.registration,
                  status: "new",
                  delete_former_vehicle: registrationNumberToReplace,
                  submitted_at: Date.now(),
                  preferred_work_area: form.area,
                  council_compliance: null,
                },
              })
                .then(({ data }) => {
                  dispatch(setVehicleApplicationId(data?.data?.id));
                  return data?.data;
                })
                .then((data) => {
                  const vehicle_make = encodeValue(form.make, FieldType.TEXT);
                  const year = encodeValue(form.year, FieldType.NUMBER_INTEGER);
                  const vehicle_model = encodeValue(form.model, FieldType.TEXT);
                  const colour = encodeValue(form.colour, FieldType.TEXT);
                  const co2_emissions = encodeValue(
                    form.emissions,
                    FieldType.TEXT,
                  );
                  const nct_expiry = encodeValue(
                    form.nctExpiry,
                    FieldType.DATETIME,
                  );
                  const vehicle_registration = encodeValue(
                    form.registration,
                    FieldType.TEXT,
                  );
                  const road_tax_expiry = encodeValue(
                    form.roadTaxExpiry,
                    FieldType.DATETIME,
                  );
                  dispatch(
                    setVehicleApplicationFormData({
                      documentType: DocumentType.VEHICLE_DETAILS,
                      data: {
                        registration: vehicle_registration,
                        area: form.area,
                        year,
                        make: vehicle_make,
                        model: vehicle_model,
                        colour: colour,
                        emissions: co2_emissions,
                        nctExpiry: nct_expiry,
                        roadTaxExpiry: road_tax_expiry,
                      },
                    }),
                  );
                  if (vehicleLookupService === "manual") {
                    return postManualVehicleLookUpWithActiveFeatures({
                      entity_id: data?.id,
                      entity_type: "vehicle_application",
                      vehicle_make,
                      year,
                      vehicle_model,
                      colour,
                      co2_emissions,
                      nct_expiry,
                      delete_former_vehicle: registrationNumberToReplace,
                      vehicle_registration,
                      road_tax_expiry,
                    });
                  } else {
                    return getDvla({ reg: form.registration });
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
          />
          <Button
            variant="secondary"
            shape="round"
            className={styles.goBack}
            block={true}
            fontWeight="bold"
            onClick={onReturnBack}
            children={t("pageAddNewVehicle.returnWithoutSavingBtn")}
          />
        </ValidationWrapper>
      </WrapperScreen>
    );
  }

  return (
    <WrapperScreen breadcrumbs key={documentTypeId}>
      <CreateDocumentForm
        isEditPage={true}
        documentTypeId={currentDocumentTypeDetails.id}
        documentTypeDetails={currentDocumentTypeDetails}
        nextPage={nextPage}
      />
      <ValidationWrapper>
        <Button
          variant="secondary"
          shape="round"
          className={styles.goBack}
          block={true}
          fontWeight="bold"
          onClick={onReturnBack}
          children={t("pageAddNewVehicle.returnWithoutSavingBtn")}
        />
        <Modal />
      </ValidationWrapper>
    </WrapperScreen>
  );
}
