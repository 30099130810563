import { Route, Routes } from "react-router-dom";
import { routes } from "routes";

import { useSynchronizeLaunchDarklyInitializationStatus } from "hooks/useSynchronizeLaunchDarklyInitializationStatus";
import { allowAllAdmins, allowSuperAdmins } from "hooks/useRoles";

import { AuthRequired } from "components/AuthRequired";
import { RolesRequired } from "components/RolesRequired";
import { DriverLayout } from "components/DriverLayout";

import { IndexPageWithGates } from "pages/IndexPage";
import { NotFoundErrorPage } from "pages/NotFoundErrorPage";

import {
  AuthWithTokenPage,
  CompleteRegistrationPage,
  CreateNewPasswordPage,
  EmailSentPage,
  EmailVerificationPageWithGates,
  ResetPasswordPage,
  SignInPage,
  WelcomePageWithGates,
} from "pages/auth";

import { TermsAndConditionsPage } from "pages/TermsAndConditionsPage";

import {
  JoinFleetPage,
  OnboardingApplicationIsSubmittedPage,
  OnboardingCheckEmailPage,
  OnboardingCreateDriverProfilePage,
  OnboardingDocumentPage,
  OnboardingLayout,
  OnboardingSelectPayoutAccountPage,
  OnboardingSignUpPage,
  OnboardingStripeKycPage,
  StripeCompletedPageWithGates,
} from "pages/onboarding";

import {
  DriverDocumentListPage,
  DriverDocumentPage,
  VehicleDocumentPage,
} from "pages/documents";

import { HomePageWithGates } from "pages/HomePage";
import { ProfilePage } from "pages/ProfilePage";
import { NotificationsPageWithGates } from "pages/NotificationsPage";
import { FaqPageWithGates } from "pages/FaqPage";

import { BookingsListPage } from "pages/BookingsListPage";
import { BookingItemPage } from "pages/BookingItemPage";

import Booking from "pagesLegacy/FleetView/Booking/Booking";
import BookingsList from "pagesLegacy/FleetView/BookingsList/BookingsList";
import { Dashboard } from "pagesLegacy/FleetView/Dashboard/Dashboard";
import Driver from "pagesLegacy/FleetView/Driver/Driver";
import DriversList from "pagesLegacy/FleetView/DriversList/DriversList";
import Fleet from "pagesLegacy/FleetView/Fleet/Fleet";
import FleetLayout from "pagesLegacy/FleetView/FleetLayout/FleetLayout";
import FleetsList from "pagesLegacy/FleetView/FleetsList/FleetsList";
import Payments from "pagesLegacy/FleetView/Payments/Payments";
import Reports from "pagesLegacy/FleetView/Reports/Reports";
import User from "pagesLegacy/FleetView/User/User";
import UsersList from "pagesLegacy/FleetView/UsersList/UsersList";

import "./App.scss";
import {
  CreateNewVehiclePage as AddNewVehiclePage,
  ReviewNewVehiclePage,
} from "pages/AddNewVehicle";

export function App() {
  useSynchronizeLaunchDarklyInitializationStatus();

  return (
    <Routes>
      <Route index element={<IndexPageWithGates />} />
      <Route path="*" element={<NotFoundErrorPage />} />
      <Route path={routes.authWithToken.path} element={<AuthWithTokenPage />} />
      <Route path={routes.signIn.path} element={<SignInPage />} />

      {/* both "signup" and "completeRegistration" routes are used; they are referenced in emails */}
      <Route path={routes.signUp.path} element={<CompleteRegistrationPage />} />
      <Route
        path={routes.completeRegistration.path}
        element={<CompleteRegistrationPage />}
      />

      <Route
        path={routes.forgetPassword.path}
        element={<ResetPasswordPage />}
      />
      <Route
        path={routes.resetPasswordEmailSent.path}
        element={<EmailSentPage />}
      />
      <Route
        path={routes.createNewPassword.path}
        element={<CreateNewPasswordPage />}
      />

      <Route
        path={routes.termsAndConditions.path}
        element={<TermsAndConditionsPage />}
      />

      <Route path={routes.joinFleet.path} element={<JoinFleetPage />} />
      <Route
        path={routes.onboardingSignUpPage.path}
        element={<OnboardingSignUpPage />}
      />
      <Route
        path={routes.checkEmail.path}
        element={<OnboardingCheckEmailPage />}
      />
      <Route
        path={routes.verifyEmail.path}
        element={<EmailVerificationPageWithGates />}
      />

      <Route element={<OnboardingLayout />}>
        <Route
          path={routes.onboardingCreateDriverProfilePage.path}
          element={<OnboardingCreateDriverProfilePage />}
        />
        <Route
          path={routes.onboardingDocument.path}
          element={<OnboardingDocumentPage />}
        />
        <Route
          path={routes.onboardingApplicationIsSubmitted.path}
          element={<OnboardingApplicationIsSubmittedPage />}
        />

        <Route
          path={routes.onboardingSelectPayoutAccount.path}
          element={<OnboardingSelectPayoutAccountPage />}
        />
        <Route
          path={routes.onboardingStripeKyc.path}
          element={<OnboardingStripeKycPage />}
        />
      </Route>

      <Route
        path={routes.stripeCompleted.path}
        element={<StripeCompletedPageWithGates />}
      />
      <Route path={routes.welcome.path} element={<WelcomePageWithGates />} />

      <Route element={<DriverLayout />}>
        <Route path={routes.walletHome.path} element={<HomePageWithGates />} />
        <Route
          path={routes.walletBookings.path}
          element={<BookingsListPage />}
        />
        <Route
          path={routes.walletBookingsItem.path}
          element={<BookingItemPage />}
        />

        <Route
          path={routes.documents.path}
          element={<DriverDocumentListPage />}
        />

        <Route
          path={routes.driverDocument.path}
          element={<DriverDocumentPage />}
        />
        <Route
          path={routes.driverDocumentEdit.path}
          element={<DriverDocumentPage isEditPage />}
        />
        <Route
          path={routes.vehicleDocument.path}
          element={<VehicleDocumentPage />}
        />
        <Route
          path={routes.vehicleDocumentEdit.path}
          element={<VehicleDocumentPage isEditPage />}
        />
        <Route
          path={routes.addNewVehicle.path}
          element={<AddNewVehiclePage />}
        />
        <Route
          path={routes.reviewNewVehicle.path}
          element={<ReviewNewVehiclePage />}
        />
        <Route path={routes.profilePage.path} element={<ProfilePage />} />
        <Route
          path={routes.notificationsPage.path}
          element={<NotificationsPageWithGates />}
        />
        <Route path={routes.faqsPage.path} element={<FaqPageWithGates />} />
        <Route path="/not-found" element={<NotFoundErrorPage />} />
      </Route>

      <Route
        path="/console"
        element={
          <AuthRequired>
            <RolesRequired allowByRoles={allowAllAdmins}>
              <FleetLayout />
            </RolesRequired>
          </AuthRequired>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="transactions" element={<BookingsList />} />
        <Route path="transactions/:bookingId" element={<Booking />} />
        <Route path="drivers" element={<DriversList />} />
        <Route path="drivers/:driverId" element={<Driver />} />
        <Route path="users" element={<UsersList />} />
        <Route
          path="users/:userId"
          element={
            <RolesRequired allowByRoles={allowSuperAdmins}>
              <User />
            </RolesRequired>
          }
        />
        <Route
          path="admin"
          element={
            <RolesRequired allowByRoles={allowSuperAdmins}>
              <FleetsList />
            </RolesRequired>
          }
        />
        <Route
          path="fleets/:fleetId"
          element={
            <RolesRequired allowByRoles={allowSuperAdmins}>
              <Fleet />
            </RolesRequired>
          }
        />
        <Route
          path="fleets/new"
          element={
            <RolesRequired allowByRoles={allowSuperAdmins}>
              <Fleet isCreatePage />
            </RolesRequired>
          }
        />
        <Route path="reports" element={<Reports />} />
        <Route path="payments" element={<Payments />} />
      </Route>
    </Routes>
  );
}
