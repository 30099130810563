import { Link } from "react-router-dom";
import { ReactComponent as BackArrow } from "assets/icons/backArrow.svg";
import clsx from "clsx";
import styles from "./SubTopNavigator.module.scss";

export const SubTopNavigator = ({ pathName, subject, className = "" }) => {
  return (
    <div className={clsx(styles.subTopWrapper, className)}>
      <Link to={`/${pathName}`} className="subTopWrapper__link">
        <BackArrow />
      </Link>
      <h1 className="subTopWrapper__header">{subject}</h1>
    </div>
  );
};
