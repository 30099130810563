import { MenuToggle } from "components/MenuToggle/MenuToggle";
import { ReportTable } from "./ReportTable/ReportTable";
import "./Reports.scss";
import { ReactComponent as Loader } from "../../../assets/icons/loader.svg";
import { Filters } from "./Filters";
import { useDispatch } from "react-redux";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { format, sub } from "date-fns";
import { generateListApiParams } from "helpers/helpers";
import { ScrollTop } from "components/ScrollTop";
import { useIsMobile } from "hooks/useMobile";
import { CustomDropdown } from "pagesLegacy/components/CustomDropdown";
import { useMediaQuery } from "react-responsive";
import { reportOptions } from "./reportOptions";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "helpers/windowMassages";
import { message } from "antd";
import { generateExportParams } from "../BookingsList/BookingsList";
import { api } from "api";
import { Button } from "components/Button";
import { useSearchParams } from "react-router-dom";
import { RedirectToCompany, useQueryCompanies } from "hooks/useQueryCompanies";

const SCROLL_TOP_ID = "reports-table";

export const Reports = () => {
  const { currentCompanyId: id } = useQueryCompanies({
    redirectTo: RedirectToCompany.ADMIN,
  });
  const [params, setParams] = useState(null);
  const [searchInputValue, setSearchValue] = useState("");
  const [urlParams] = useSearchParams();
  const firstDate = urlParams.get("firstDate");
  const lastDate = urlParams.get("lastDate");

  const initialDate =
    firstDate && lastDate
      ? [new Date(firstDate), new Date(lastDate)]
      : [sub(new Date(), { months: 1 }), new Date()];

  const [date, setDate] = useState(initialDate);

  const initialParams = (id) =>
    generateListApiParams(id, "fleet_id", true, 1, 25, {
      date_from: format(initialDate[0], "yyyy-MM-dd"),
      date_to: format(initialDate[1], "yyyy-MM-dd"),
    });

  const isMobile = useIsMobile();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1500px)" });
  const dispatch = useDispatch();
  const [selectedReports, setSelectedReports] = useState({});
  const selectedReportsLength = Object.keys(selectedReports).length;

  // Api state
  const resetApiState = useCallback(() => {
    setSelectedReports({});
    dispatch(
      api.util.updateQueryData("getReports", undefined, (reports) => {
        reports.data = [];
      }),
    );
  }, [dispatch]);

  const {
    data: reports,
    isLoading,
    isFetching,
  } = api.endpoints.getReports.useQuery(params, {
    skip: !params,
  });
  const [exportReports] = api.endpoints.exportReport.useMutation();
  const [exportReportsBulk] = api.endpoints.exportPayouts.useMutation();

  const isUninitialized = typeof reports === "undefined";

  const reportsList = reports?.data || [];
  const loading = isLoading || isFetching;
  const paginationInfo = useMemo(() => {
    const pagination = reports?.meta;

    if (!pagination) {
      return;
    }

    return {
      lastPage: pagination.last_page,
      currentPage: pagination.current_page,
      total: pagination?.total,
      nextPage: pagination?.next_cursor,
    };
  }, [reports?.meta]);

  const getNextReports = () => {
    setParams((prevParams) => {
      return {
        ...prevParams,
        cursor: paginationInfo.nextPage,
      };
    });
  };

  // Effects
  useLayoutEffect(() => {
    if (id) {
      resetApiState();
      setParams(initialParams(id));
    }
  }, [id, resetApiState]);

  const handleSelectReport = (report) => {
    const reportid = report.external_id;
    setSelectedReports((currentReports) => {
      if (currentReports[reportid]) {
        return Object.keys(currentReports).reduce(
          (prevReports, currentReportId) => {
            if (currentReportId.toString() === reportid.toString()) {
              return prevReports;
            }

            return {
              ...prevReports,
              [currentReportId]: currentReports[currentReportId],
            };
          },
          {},
        );
      }

      return {
        ...currentReports,
        [reportid]: report,
      };
    });
  };

  const onClearSearch = useCallback(
    (value) => {
      setSearchValue(value);
      if (!value) {
        resetApiState();
        setParams(initialParams(id));
      }
    },
    [id, resetApiState],
  );

  const onSearch = useCallback(
    (e) => {
      if (e.target.value) {
        resetApiState();
        setDate(initialDate);
        setParams(({ cursor, ...currentParams }) => ({
          ...currentParams,
          driver_ref: e.target.value,
          page: 1,
        }));
      } else {
        resetApiState();
        setParams(initialParams(id));
      }
    },
    [resetApiState, id],
  );

  const onDateChange = useCallback(
    ([firstDate, lastDate]) => {
      const first = firstDate || new Date();
      const last = lastDate || new Date();
      setDate([first, last]);
      resetApiState();
      setParams((currentParams) => ({
        ...currentParams,
        date_from: format(first, "yyyy-MM-dd"),
        date_to: format(last, "yyyy-MM-dd"),
        page: 1,
      }));
    },
    [resetApiState],
  );

  const onExport = useCallback(
    (individualReport) => {
      showLoadingMsg({
        content: "Exporting Payouts...",
        key: "payout_export",
      });
      const { date_from, date_to, driver_ref, fleet_id } = params;
      let apiParams = {};

      const model_ids = Object.keys(selectedReports);
      if (individualReport) {
        apiParams = {
          driver_ref: individualReport.driver.ref,
          fleet_id,
        };
      } else {
        apiParams = generateExportParams({
          fleet_id: id,
          model_ids,
          date_from,
          date_to,
          driver_ref,
        });
      }

      let payoutName = "Payouts";
      if (individualReport) {
        payoutName = "Payout";
      }
      exportReports(apiParams)
        .unwrap()
        .then(() => {
          // show success here
          showSuccessMsg({ content: `${payoutName} successfuly exported` });
        })
        .catch(() => {
          // show error here
          showErrorMsg({ content: `${payoutName} couldn't export` });
        })
        .finally(() => {
          message.destroy("payout_export");
        });
    },
    [exportReports, id, params, selectedReports],
  );

  const onExportBulk = useCallback(() => {
    showLoadingMsg({
      content: "Exporting Payouts...",
      key: "payout_export",
    });
    const { date_from, date_to } = params;
    exportReportsBulk({
      fleetId: id,
      data: {
        date_from,
        date_to,
      },
    })
      .unwrap()
      .then(() => {
        // show success here
        showSuccessMsg({ content: "Payouts successfuly exported" });
      })
      .catch(() => {
        // show error here
        showErrorMsg({ content: "Payouts couldn't export" });
      })
      .finally(() => {
        message.destroy("payout_export");
      });
  }, [exportReportsBulk, id, params]);

  const onItemSelect = useCallback(
    (type) => {
      if (type === "export") {
        return onExport();
      }
      if (type === "export_dispatch") {
        return onExportBulk();
      }
    },
    [onExport, onExportBulk],
  );

  const reportDropdown = useMemo(() => {
    return (
      <CustomDropdown
        options={reportOptions}
        onItemSelect={onItemSelect}
        mobileContent={
          <div className="mobile-report-action-drawer">
            <h1>Actions</h1>
            <p>
              {selectedReportsLength
                ? `Export a CSV file of the Driver Pay payouts you have selected (${selectedReportsLength}).`
                : "Export a CSV file of the payoyts"}
            </p>
            <Button shape="round" onClick={() => onExport()}>Export</Button>
            <Button shape="round" onClick={() => onExportBulk()}>Export for Dispatch</Button>
          </div>
        }
      />
    );
  }, [onExport, onExportBulk, onItemSelect, selectedReportsLength]);

  const pageHeader = useMemo(() => {
    return (
      <>
        <MenuToggle />
        <h1>Payout Reports</h1>
        {isTabletOrMobile && reportDropdown}
      </>
    );
  }, [isTabletOrMobile, reportDropdown]);

  return (
    <div className="reports-wrapper">
      <ReportTable
        getNextReports={getNextReports}
        id={id}
        isUninitialized={isUninitialized}
        loading={loading}
        paginationInfo={paginationInfo}
        reportsList={reportsList}
        topScrollId={SCROLL_TOP_ID}
        headerSection={
          <div className="actions-header-section">
            {isMobile ? (
              <div className="reports-sticky-header">{pageHeader}</div>
            ) : (
              pageHeader
            )}
            {id && (
              <Filters
                searchInputValue={searchInputValue}
                onClearSearch={onClearSearch}
                onSearch={onSearch}
                date={date}
                onDateChange={onDateChange}
                dropdown={reportDropdown}
                showDropdown={!isTabletOrMobile}
              />
            )}
          </div>
        }
        onRowSelect={handleSelectReport}
        selectedReports={selectedReports}
        onExport={onExport}
      />
      <div className="reports-footer">
        <span>{loading && <Loader />}</span>
        <ScrollTop scrollId={SCROLL_TOP_ID} />
      </div>
    </div>
  );
};

export default Reports;
