import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { routes } from "routes";

import { api } from "api";

import { Spacer } from "components/Spacer";
import { Title } from "components/Title";
import { Description } from "components/Description";
import { Button } from "components/Button";

import { ValidationWrapper } from "../../documents";

import { last } from "lodash";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { LoadingComp } from "components/LoadingComp";
import { useDispatch } from "react-redux";
import {
  clearVehicleApplicationFormData,
  setNewVehicleRegistratioinNumber,
  setVehicleApplicationId,
} from "store/slices/addNewVehicleSlice";
import { useApplicationCompleted } from "./useApplicationCompleted";
import { destroySnack, errorSnack, loadingSnack, successSnack } from "helpers/message";

export function SubmitApplicationPage() {
  const { t } = useTranslation();
  const { selectedDriverProfile } = useSelectedFleet();

  const { data: vehicleAplications = { data: [] }, isLoading: isGetting } =
    api.endpoints.getVehicleApplications.useQuery({
      driverId: selectedDriverProfile?.id,
    });

  const isApplicationCompleted = useApplicationCompleted();

  const currentVehicleApplication = last(
    vehicleAplications?.data.filter(
      ({ submitted_at }) => submitted_at === null,
    ),
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [
    updateVehicleApplications,
    {
      isLoading: isPatching,
      isSuccess: isPatchingSucceed,
      isError: isPatchingFailed,
      reset,
    },
  ] = api.endpoints.updateVehicleApplications.useMutation();

  useEffect(() => {
    if (isPatching) {
      loadingSnack({
        content: t("processing.applicationSubmitting"),
        duration: 0,
        key: "submitting_application",
      });
    } else {
      destroySnack("submitting_application");
    }
  }, [isPatching, t]);

  useEffect(() => {
    if (isPatchingFailed) {
      errorSnack({
        content: t("error.applicationSubmittingFailed"),
      });
    }

    if (isPatchingSucceed) {
      successSnack({
        content: t("success.applicationSubmitted"),
      });
    }

    if (isPatchingSucceed || isPatchingFailed) {
      reset();
      destroySnack("submitting_application");
    }
  }, [isPatchingSucceed, isPatchingFailed, reset, t]);

  useEffect(() => {
    if (isPatchingSucceed) {
      navigate(routes.documents.getURL());
      dispatch(clearVehicleApplicationFormData("all"));
      dispatch(setVehicleApplicationId(null));
      dispatch(setNewVehicleRegistratioinNumber(null));
    }
    return;
  }, [isPatchingSucceed, navigate]);

  function onSubmit() {
    updateVehicleApplications({
      vehicleApplicationId: currentVehicleApplication?.id,
      data: {
        delete_former_vehicle: currentVehicleApplication?.delete_former_vehicle,
        status: "new",
        vehicle_registration: currentVehicleApplication?.vehicle_registration,
        preferred_work_area: currentVehicleApplication?.preferred_work_area,
        council_compliance: currentVehicleApplication?.council_compliance,
      },
    });
  }

  if (isGetting) {
    return <LoadingComp loading />;
  }

  return (
    <ValidationWrapper>
      <Title>{t("pageSubmitApplication.submitApplication")}</Title>
      <Description>{t("pageSubmitApplication.youReceiveEmail")}</Description>
      <Spacer />

      {!isApplicationCompleted ? (
        <Description importance="error">
          {t("pageSubmitApplication.youSubmitIncompleteApplication")}
        </Description>
      ) : null}
      <Spacer />

      <Button
        block
        variant="primary"
        shape="round"
        fontWeight="bold"
        onClick={onSubmit}
      >
        {t("action.submitApplication")}
      </Button>
    </ValidationWrapper>
  );
}
