import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Modal } from "antd";

import { routes } from "routes";

import { Checkbox } from "components/Checkbox";
import { Link } from "../Link";
import { Spacer } from "../Spacer";
import { Field } from "../Field";
import { Button } from "../Button";

export function ModalLatestUserAgreementVersionNotAccepted({ open, onSubmit }) {
  const { t } = useTranslation();

  const [isAccepted, setIsAccepted] = useState(false);

  return (
    <Modal
      open={open}
      title={t("modalTermsNotAccepted.termsNotAccepted")}
      footer={null}
      closable={false}
      zIndex={2000}
      centered
    >
      <Trans
        i18nKey="modalTermsNotAccepted.readAndAcceptToContinueUsing"
        components={{
          termslink: (
            <Link to={routes.termsAndConditions.getURL()} target="_blank" />
          ),
        }}
      />
      <Spacer />
      <Field>
        <Checkbox
          checked={isAccepted}
          onChange={(event) => setIsAccepted(event.target.checked)}
        >
          <Trans
            i18nKey="modalTermsNotAccepted.iReadAndAcceptTerms"
            components={{
              termslink: (
                <Link to={routes.termsAndConditions.getURL()} target="_blank" />
              ),
            }}
          />
        </Checkbox>
      </Field>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="primary"
          shape="round"
          disabled={!isAccepted}
          onClick={onSubmit}
        >
          {t("action.submit")}
        </Button>
      </div>
    </Modal>
  );
}
