import { forwardRef } from "react";
import { Radio as Inner } from "antd";
import { Radio } from "./Radio";
import clsx from "clsx";
import PropTypes from "prop-types";

import styles from "./Radio.module.scss";

const { Group } = Inner;
export const RadioGroup = forwardRef(function RadioGroup(
  { className, list, isGroup, ...otherProps },
  ref,
) {
  const controls = list.map(({ value, label, ...rest }) => (
    <Radio key={value} value={value} {...rest}>
      {label}
    </Radio>
  ));
  return isGroup ? (
    <Group
      className={clsx(styles.customRadioGroup, className)}
      {...otherProps}
      ref={ref}
    >
      {controls}
    </Group>
  ) : (
    <>{controls}</>
  );
});

RadioGroup.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired,
    }),
  ),
  isGroup: PropTypes.bool,
  otherProps: PropTypes.object,
};

RadioGroup.defaultProps = {
  className: "",
  isGroup: true,
  list: [],
};
