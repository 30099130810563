import { Navigate, useNavigate } from "react-router-dom";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useState } from "react";

export function SelectedCompanyFeatureFlagRequired({
  children,
  allowByFeatureFlags = () => false,
  redirectToWhenNotAllowed = "/not-found",
}) {
  const { selectedFleet } = useSelectedFleet();

  const isDriverPayEnabled = Boolean(selectedFleet?.enable_driver_pay);
  const isDocumentsFeatureEnabled = Boolean(
    selectedFleet?.enable_documents_feature,
  );

  if (!selectedFleet) {
    return null;
  }

  const isAllowed = allowByFeatureFlags({
    isDriverPayEnabled,
    isDocumentsFeatureEnabled,
  });

  if (!isAllowed) {
    // if return <NotFoundPage>, then it will render alongside header and sidebar nav;
    // if navigate to unknown route, then it will render only <NotFoundPage>;
    return <Navigate to={redirectToWhenNotAllowed} replace />;
  }

  return children;
}

export function SelectedCompanyFeatureFlagAlternativeImplementation({
  children,
}) {
  const { selectedFleet } = useSelectedFleet();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  const isDriverPayEnabled = Boolean(selectedFleet?.enable_driver_pay);
  const isDocumentsFeatureEnabled = Boolean(
    selectedFleet?.enable_documents_feature,
  );

  if (!selectedFleet) {
    return null;
  }

  if (!isDriverPayEnabled && isDocumentsFeatureEnabled) {
    return <Navigate to={routes.documents.getURL()} replace />;
  }
  if (!isDriverPayEnabled && !isDocumentsFeatureEnabled) {
    return (
      <Modal
        open={isOpen}
        title={t("fleetFeatures.title")}
        footer={null}
        zIndex={2000}
        onCancel={() => {
          navigate(routes.profilePage.getURL(), { replace: true });
          setIsOpen(false);
        }}
        centered
      >
        {t("fleetFeatures.description")}
      </Modal>
    );
  }

  return children;
}
