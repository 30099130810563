import clsx from "clsx";
import { InfiniteScrollComp } from "components/InfiniteScroll";
import { StateHandler } from "components/LoadingComp";
import { MobileTableItem } from "components/MobileTableItem";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useIsMobile } from "hooks/useMobile";
import { useQueryCompanies } from "hooks/useQueryCompanies";
import { useWindowSize } from "hooks/useWindowSize";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { twoDecimals } from "../../../../helpers/twoDecimal";
import { bookingsHeaderItems, statusColor } from "./constants";
import capitalize from "lodash/capitalize";
import { Checkbox } from "components/Checkbox";

export const BOOKING_LIST_TABLE_WRAPPER = "booking-list-table-section-wrapper";

export const Table = ({
  data: bookings,
  loading,
  id,
  getNextBookings,
  hasMore,
  onScroll,
  wrapperRef,
  onRowSelect,
  selectedBookings,
  topScrollId,
  headerSection,
  isUninitialized,
  apiLoading,
}) => {
  const navigate = useNavigate();
  const [wrapperComponent, setWrapperComponent] = useState(null);
  useWindowSize();
  const { currentCompanyCurrency: selectedCompanyCurrency } =
    useQueryCompanies();

  const onWrapperMount = useCallback((node) => {
    if (node) {
      setWrapperComponent(node);
    }
  }, []);

  const isMobile = useIsMobile();

  const onHnadleRedirect = (id) => {
    navigate(`${id}`);
  };

  const wrapperHeight = wrapperComponent?.clientHeight;

  const showEmpty = useMemo(() => {
    return !isUninitialized && !apiLoading && !loading && !bookings?.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiLoading]);

  const emptyMessage = useMemo(() => {
    if (!id) {
      return;
    }

    return "Try changing your filters";
  }, [id]);

  return (
    <div ref={onWrapperMount} className="bookings-table-wrapper">
      <div className={clsx(!loading && "bookings-table-container")}>
        <StateHandler
          companySelected={!id}
          showLoader={false}
          showEmpty={showEmpty}
          emptyTitle="transactions"
          headerComponent={headerSection}
          customEmptyMessage={emptyMessage}
        >
          <InfiniteScrollComp
            dataLength={bookings?.length}
            next={getNextBookings}
            height={`${wrapperHeight}px`}
            hasMore={hasMore}
            topic="transactions"
            scrollThreshold={0.95}
            wrapperRef={wrapperRef}
            onScroll={onScroll}
          >
            <div className="booking-list-header-section-wrapper">
              {headerSection}
            </div>
            <div className="booking-list-table-wrapper">
              <div className={BOOKING_LIST_TABLE_WRAPPER}>
                {!isMobile && (
                  <div className="header">
                    {bookingsHeaderItems.map((item, index) => {
                      return (
                        <div key={index}>
                          <p>{item.toUpperCase()}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
                <span id={topScrollId}></span>
                {isMobile ? (
                  <>
                    {bookings?.map((booking) => {
                      const {
                        id,
                        date,
                        driver,
                        payment_type,
                        total_amount,
                        payout_status,
                        total_cost,
                        booking_id,
                      } = booking;
                      return (
                        <div key={id} className="mobile-row">
                          <MobileTableItem
                            fields={[
                              {
                                label: "Booking Date",
                                render: format(
                                  utcToZonedTime(new Date(date), "UTC"),
                                  "dd/MM/yyyy",
                                ),
                              },
                              {
                                label: "Booking Time",
                                render: format(
                                  utcToZonedTime(new Date(date), "UTC"),
                                  "HH:mm",
                                ),
                              },
                              {
                                label: "Driver Ref",
                                render: driver?.ref,
                              },
                              {
                                label: "Driver Name",
                                render: driver?.name?.toLowerCase(),
                                contentClass: "capitalize",
                              },
                              {
                                label: "Payment Type",
                                render: capitalize(payment_type),
                              },
                              {
                                label: "Gross (Fare Amt.)",
                                render: `${selectedCompanyCurrency}${twoDecimals(
                                  total_amount / 100,
                                )}`,
                                bold: true,
                              },
                              {
                                label: "Driver Earns",
                                render: `${selectedCompanyCurrency}${twoDecimals(
                                  total_cost / 100,
                                )}`,
                                bold: true,
                              },
                              {
                                label: "Payout Status",
                                render: (
                                  <div className="status-tag cell">
                                    <div
                                      style={{
                                        color:
                                          statusColor[
                                            payout_status.toLowerCase()
                                          ],
                                        border: `1px solid ${
                                          statusColor[
                                            payout_status.toLowerCase()
                                          ]
                                        }`,
                                      }}
                                    >
                                      {payout_status.toUpperCase()}
                                    </div>
                                  </div>
                                ),
                              },
                            ]}
                            isSelected={selectedBookings.includes(booking.id)}
                            onSelect={(e) => onRowSelect(e, booking)}
                            selectLabelComponent={
                              <p>
                                ID :{" "}
                                <b onClick={() => onHnadleRedirect(booking.id)}>
                                  {booking_id}
                                </b>
                              </p>
                            }
                          />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {bookings?.map((booking, index) => {
                      const {
                        id,
                        date,
                        driver,
                        booking_id,
                        payment_type,
                        total_amount,
                        payout_status,
                        total_cost,
                      } = booking;
                      const isRowSelected = selectedBookings.includes(
                        booking.id,
                      );

                      return (
                        <div
                          className={clsx("row", isRowSelected && "selected")}
                          key={index}
                        >
                          <div className="cell">
                            <Checkbox
                              checked={isRowSelected}
                              onChange={(e) => onRowSelect(e, booking)}
                            />
                            {format(
                              utcToZonedTime(new Date(date), "UTC"),
                              "dd / MM / yyyy",
                            )}
                          </div>
                          <div className="cell">
                            {format(
                              utcToZonedTime(new Date(date), "UTC"),
                              "HH:mm",
                            )}
                          </div>
                          <div className="cell">{driver?.ref}</div>
                          <div
                            className="cell booking_id"
                            onClick={() => onHnadleRedirect(id)}
                          >
                            {booking_id}
                          </div>
                          <div className="cell capitalize">
                            {driver?.name?.toLowerCase()}
                          </div>
                          <div className="cell payment-type">
                            {capitalize(payment_type)}
                          </div>
                          <div className="cell">
                            {selectedCompanyCurrency}
                            {twoDecimals(total_amount / 100)}
                          </div>
                          <div className="cell">
                            {selectedCompanyCurrency}
                            {twoDecimals(total_cost / 100)}
                          </div>
                          <div className="status-tag cell">
                            <div
                              style={{
                                color: statusColor[payout_status.toLowerCase()],
                                border: `1px solid ${
                                  statusColor[payout_status.toLowerCase()]
                                }`,
                              }}
                            >
                              {payout_status.toUpperCase()}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </InfiniteScrollComp>
        </StateHandler>
      </div>
    </div>
  );
};

export default Table;
