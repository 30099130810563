import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { api, UserRoles } from "api";
import { routes } from "routes";

import { WaveLayout } from "components/WaveLayout";
import { Container } from "components/Container";
import { Title } from "components/Title";
import { Spacer } from "components/Spacer";

import { CreateNewPasswordForm } from "./CreateNewPasswordForm";
import { prepareErrorMessage } from "helpers/prepareErrorMessage";
import { errorSnack, loadingSnack, successSnack } from "helpers/message";

export function CreateNewPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") ?? "";
  const token = searchParams.get("token") ?? "";

  const [updatePassword] = api.endpoints.updatePassword.useMutation();
  const [logIn] = api.endpoints.login.useMutation();

  const onError = (error) => {
    const content = prepareErrorMessage(error) ?? t("error.somethingWentWrong");

    const politeContentMessage =
      content === "Your account has been deleted."
        ? "The email you have entered doesn’t match any active users"
        : content;

    errorSnack({
      content: politeContentMessage,
      key: "sign-in",
    });
  };

  const onSubmit = useCallback(
    ({ password, passwordConfirm }) => {
      loadingSnack({
        content: t("processing.changingPassword"),
        key: "sign-in",
      });

      updatePassword({
        token,
        body: {
          email,
          password,
          password_confirmation: passwordConfirm,
        },
      })
        .unwrap()
        .then(() => {
          loadingSnack({
            content: t("processing.signingIn"),
            key: "sign-in",
          });

          logIn({
            email,
            password,
          })
            .unwrap()
            .then((res) => {
              const { data, meta } = res;
              localStorage.setItem("auth_token", JSON.stringify(meta.token));
              const roles = data?.roles || [];

              const isAdmin = [
                UserRoles.FLEET_ADMIN,
                UserRoles.FLEET_ADMIN_READONLY,
                UserRoles.SUPER_ADMIN,
              ].some((element) => roles.includes(element));

              const isDriver = roles.includes(UserRoles.DRIVER);

              const isDriverAndAdmin = isDriver && isAdmin;

              successSnack({
                content: t("success.signedIn"),
                key: "sign-in",
              });

              if (isDriverAndAdmin) {
                return navigate(routes.welcome.getURL(), { replace: true });
              }

              if (isAdmin) {
                return navigate("/console/dashboard", { replace: true });
              }

              if (isDriver) {
                return navigate(routes.walletHome.getURL(), { replace: true });
              }
            })
            .catch(onError);
        })
        .catch(onError);
    },
    [t, email, token, updatePassword, logIn],
  );

  return (
    <WaveLayout>
      <Container width="small">
        <Title>{t("pagePasswordCreateNew.createNewPassword")}</Title>
        <Spacer />
        <CreateNewPasswordForm onSubmit={onSubmit} />
      </Container>
    </WaveLayout>
  );
}
