import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Field } from "components/Field";

import { Spacer } from "components/Spacer";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { Title } from "components/Title";
import { RadioGroup } from "components/RadioGroup";

import { ValidationWrapper } from "../ValidationWrapper";
import { decodeValue } from "../converters";
import { FieldType } from "../FieldType";

import { DvlaLookupServiceForm } from "./DvlaLookupServiceForm";
import { ManualLookupServiceFieldset } from "./ManualLookupServiceFieldset";

export function VehicleDetailsOnboardingForm({
  document,
  buttons = [],
  onSave,
  vehicleLookupService = "manual",
  className = "",
}) {
  const { t } = useTranslation();

  const [hasOwnVehicle, setHasOwnVehicle] = useState(false);

  const [extraInfo, setExtraInfo] = useState({
    area: "",
  });

  const [vehicleInfo, setVehicleInfo] = useState({
    registration: "",
    year: "",
    make: "",
    model: "",
    colour: "",
    emissions: "",
    nctExpiry: undefined,
    roadTaxExpiry: undefined,
  });

  const [errors, setErrors] = useState({});

  useEffect(
    function syncInternalStateWithDocument() {
      setHasOwnVehicle(document.hasOwnVehicle ?? false);

      setExtraInfo({
        area: decodeValue(document.area, FieldType.TEXT),
      });

      setVehicleInfo({
        registration: decodeValue(document.registration, FieldType.TEXT),
        year: decodeValue(document.year, FieldType.TEXT),
        make: decodeValue(document.make, FieldType.TEXT),
        model: decodeValue(document.model, FieldType.TEXT),
        colour: decodeValue(document.colour, FieldType.TEXT),
        emissions: decodeValue(document.emissions, FieldType.TEXT),
        nctExpiry: decodeValue(document.nctExpiry, FieldType.DATETIME),
        roadTaxExpiry: decodeValue(document.roadTaxExpiry, FieldType.DATETIME),
      });
    },
    [document, setExtraInfo, setVehicleInfo],
  );

  function onDocumentSave() {
    const form = {
      ...extraInfo,
      ...vehicleInfo,
      hasOwnVehicle,
      vehicleLookupService,
    };

    const errors = getValidationErrors(form, t);
    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    onSave(form);
  }

  function onVehicleFieldsetChange(fieldName, fieldValue) {
    setVehicleInfo((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [fieldName]: undefined,
    }));
  }

  const onVehicleDetailsChange = useCallback(
    function (patch) {
      setVehicleInfo((prevState) => ({
        ...prevState,
        ...patch,
      }));
    },
    [setVehicleInfo],
  );

  return (
    <ValidationWrapper className={className}>
      <Title>{t("documentTypeVehicleDetails.name")}</Title>
      <Field label={t("field.doYouHaveOwnVehicle")}>
        <RadioGroup
          onChange={(event) => {
            setHasOwnVehicle(event.target.value);
          }}
          value={hasOwnVehicle}
          list={[
            {
              value: true,
              label: t("optionGeneral.yes"),
            },
            {
              value: false,

              label: t("optionGeneral.no"),
            },
          ]}
        />
      </Field>
      {hasOwnVehicle && vehicleLookupService === "dvla" ? (
        <DvlaLookupServiceForm
          data={vehicleInfo}
          errors={errors}
          setErrors={setErrors}
          onVehicleDetailsChange={onVehicleDetailsChange}
          onVehicleFieldsetChange={onVehicleFieldsetChange}
        />
      ) : null}

      {hasOwnVehicle && vehicleLookupService === "manual" ? (
        <ManualLookupServiceFieldset
          data={vehicleInfo}
          errors={errors}
          onChange={onVehicleFieldsetChange}
        />
      ) : null}

      <Field label={t("field.preferredArea")} htmlFor="area">
        <Input
          id="area"
          name="area"
          value={extraInfo.area}
          onChange={(event) => {
            setExtraInfo((prevState) => ({
              ...prevState,
              area: event?.target?.value,
            }));
          }}
        />
      </Field>

      {buttons.map((buttonProps, index) => {
        const { onClick, ...otherProps } = buttonProps;
        const { shape = "round" } = otherProps;

        return (
          <Fragment key={otherProps.children}>
            <Button
              {...otherProps}
              shape={shape}
              onClick={onClick === "onSave" ? onDocumentSave : onClick}
            />
            {index !== buttons.length - 1 ? <Spacer /> : null}
          </Fragment>
        );
      })}
    </ValidationWrapper>
  );
}

function getValidationErrors(form, t) {
  const errors = {};

  if (!form?.hasOwnVehicle) {
    return errors;
  }

  if (form?.registration?.length === 0) {
    errors.registration = t("error.fieldShouldNotBeEmpty");
  }

  if (form?.vehicleLookupService !== "manual") {
    return errors;
  }

  if (form?.year?.length === 0) {
    errors.year = t("error.fieldShouldNotBeEmpty");
  }

  if (form?.nctExpiry === undefined || form?.nctExpiry === null) {
    errors.nctExpiry = t("error.fieldShouldNotBeEmpty");
  }

  if (form?.roadTaxExpiry === undefined || form?.roadTaxExpiry === null) {
    errors.roadTaxExpiry = t("error.fieldShouldNotBeEmpty");
  }

  return errors;
}
