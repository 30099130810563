import { useMemo, useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { api } from "api";
import { routes } from "routes";

import { WaveLayout } from "components/WaveLayout";
import { Container } from "components/Container";
import { Title } from "components/Title";
import { Spacer } from "components/Spacer";

import { ResetPasswordForm } from "./ResetPasswordForm";
import { prepareErrorMessage } from "helpers/prepareErrorMessage";
import { destroySnack, errorSnack, loadingSnack, successSnack } from "helpers/message";

export function ResetPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [urlParams] = useSearchParams();
  const initialEmail = urlParams.get("email") ?? "";

  const initialValues = useMemo(() => {
    return {
      email: initialEmail,
    };
  }, [initialEmail]);

  const [forgetPassword] = api.endpoints.forgetPassword.useMutation();

  const resetPassword = useCallback(
    ({ email }) => {
      loadingSnack({
        content: t("processing.emailSending"),
        duration: 0,
        key: "sendingEmail",
      });

      forgetPassword({
        email,
      })
        .unwrap()
        .then(() => {
          successSnack({
            content: t("success.resetPasswordLinkSent"),
            duration: 5,
            key: "emailSent",
          });

          navigate(routes.resetPasswordEmailSent.getURL(email));
        })
        .catch(({ data }) => {
          const content =
          prepareErrorMessage(data) ?? t("error.somethingWentWrong");

          errorSnack({
            content,
            duration: 5,
            key: "emailError",
          });
        })
        .finally(() => {
          destroySnack("sendingEmail");
        });
    },
    [t, forgetPassword, navigate],
  );

  return (
    <WaveLayout>
      <Container width="small">
        <Title>{t("pagePasswordReset.resetPassword")}</Title>
        <Spacer />
        <ResetPasswordForm
          initialValues={initialValues}
          onSubmit={resetPassword}
        />
      </Container>
    </WaveLayout>
  );
}
