import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Banner } from "components/Banner";
import styles from "./LeftDetails.module.scss";

export const LeftDetails = ({ data: booking }) => {
  const { t } = useTranslation();

  const { date, pick_up, destination, payment_type } = booking;
  return (
    <div className={styles.leftDetails}>
      <div className={styles.leftDetailsRow}>
        <Banner
          className={styles.leftDetailsBanner}
          headingLevel="h4"
          headingText={t("pageBookingDetails.date")}
          description={format(new Date(date), "LLL dd, yyyy")}
        />
        <Banner
          className={styles.leftDetailsBanner}
          headingLevel="h4"
          headingText={t("pageBookingDetails.time")}
          description={format(new Date(date), "hh:mm a")}
        />
      </div>
      <div className={styles.leftDetailsRow}>
        <Banner
          className={styles.leftDetailsBanner}
          headingLevel="h4"
          headingText={t("pageBookingDetails.from")}
          description={pick_up}
        />
      </div>
      <div className={styles.leftDetailsRow}>
        <Banner
          className={styles.leftDetailsBanner}
          headingLevel="h4"
          headingText={t("pageBookingDetails.to")}
          description={destination}
        />
      </div>
      <div className={styles.leftDetailsRow}>
        <Banner
          className={styles.leftDetailsBanner}
          headingLevel="h4"
          headingText={t("pageBookingDetails.paymentType")}
          description={payment_type}
        />
        {/* <Banner
        className={styles.leftDetailsBanner}
          headingLevel="h4"
          headingText="booking source"
          description={"booking source here"}
        /> */}
      </div>
    </div>
  );
};
