import { forwardRef } from "react";
import { Checkbox as CheckboxInner } from "antd";
import clsx from "clsx";
import PropTypes from "prop-types";
import styles from "./Checkbox.module.scss";

export const Checkbox = forwardRef(function Checkbox(
  { className, ...otherProps },
  ref,
) {
  return (
    <CheckboxInner
      className={clsx(styles.customCheckbox, className)}
      {...otherProps}
      ref={ref}
    >
      {otherProps.children}
    </CheckboxInner>
  );
});

Checkbox.propTypes = {
  className: PropTypes.string,
  otherProps: PropTypes.object,
};

Checkbox.defaultProps = {
  className: "",
};
