import { api } from "api";
import { useTranslation } from "react-i18next";

import { Empty } from "components/Empty";
import { LoadingComp } from "components/LoadingComp";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { DriverDocumentLayout } from "../DriverDocumentLayout";
import { DocumentsPage } from "./DocumentsPage";

export function DriverDocumentListPage() {
  const { selectedDriverProfile } = useSelectedFleet();
  const { t } = useTranslation();
  const { data, isLoading } = api.endpoints.getDriverById.useQuery(
    selectedDriverProfile?.id,
  );
  const isDocumentsDisabled = data?.data?.documents_disabled === true;

  if (isLoading) {
    return <LoadingComp loading fullScreen />;
  }
  return (
    <DriverDocumentLayout>
      {isDocumentsDisabled ? (
        <Empty
          customEmptyMessage={t("pageDocument.documentsHasBeenDisabled")}
          title={t("navigation.documents")}
        />
      ) : (
        <DocumentsPage />
      )}
    </DriverDocumentLayout>
  );
}
