import { useTranslation } from "react-i18next";

import { Field } from "components/Field";
import { Form } from "components/Form";
import { SelectCountry, SelectCurrency } from "components/Select";
import { Input } from "components/Input";

export function StripeBankAccountForm({
  initialValues,
  placeholders,
  onSubmit,
  renderFooter,
}) {
  const { t } = useTranslation();

  return (
    <Form
      initialValues={initialValues}
      validationRules={[
        {
          field: "accountNumber",
          isValid: (value) => typeof value === "string" && value.length > 0,
          error: t("error.accountNumberRequired"),
        },
      ]}
      onSubmit={onSubmit}
    >
      {({ values, errors, onChange }) => {
        return (
          <div>
            <Field label={t("field.currency")} htmlFor="currency">
              <SelectCurrency
                id="currency"
                name="currency"
                limit="availableOnBackend"
                value={values.currency}
                onChange={onChange}
              />
            </Field>

            <Field label={t("field.countryOfBankAccount")} htmlFor="country">
              <SelectCountry
                id="country"
                name="country"
                value={values.country}
                onChange={onChange}
              />
            </Field>

            <Field
              label={t("field.accountNumber")}
              htmlFor="accountNumber"
              error={errors.accountNumber}
            >
              <Input
                invalid={errors.accountNumber?.length > 0}
                type="text"
                id="accountNumber"
                name="accountNumber"
                placeholder={placeholders.accountNumber}
                value={values.accountNumber}
                onChange={onChange}
              />
            </Field>

            <Field
              label={t("field.routingNumber")}
              htmlFor="routingNumber"
              description={t("field.routingNumberDescription")}
            >
              <Input
                type="text"
                id="routingNumber"
                name="routingNumber"
                value={values.routingNumber}
                onChange={onChange}
              />
            </Field>
            {renderFooter()}
          </div>
        );
      }}
    </Form>
  );
}
