import React from "react";
import clsx from "clsx";

import { Input } from "components/Input";
import { InputError } from "./InputError";
import styles from "./FormInput.module.scss";

export const FormInput = (inputProps) => {
  return (
    <div className={clsx(styles.inputWrapper, inputProps.wrapperClassName)}>
      <Input
        status={inputProps.error ? "error" : undefined}
        {...inputProps}
        className={clsx(
          styles.input,
          inputProps?.disabled && styles.inputDisabled,
          inputProps.className,
        )}
      />
      {inputProps.error && (
        <InputError className={styles.errorText} text={inputProps.error} />
      )}
    </div>
  );
};
