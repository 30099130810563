import clsx from "clsx";
import { DateFnsDatePicker } from "./DateFnsDatePicker";
import styles from "./DatePicker.module.scss";

export function DatePicker({ invalid, isRange = false, ...otherProps }) {
  return isRange ? (
    <DateFnsDatePicker.RangePicker
      className={clsx(styles.customDatePicker, otherProps.className, {
        [styles.invalid]: invalid,
      })}
      popupClassName={styles.customDatePickerPannels}
      {...otherProps}
    />
  ) : (
    <DateFnsDatePicker
      className={clsx(styles.customDatePicker, {
        [styles.invalid]: invalid,
      })}
      popupClassName={styles.customDatePickerPannels}
      {...otherProps}
    />
  );
}
