import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { ReactComponent as ExportIcon } from "assets/icons/export.svg";
import styles from "./ExportButton.module.scss";

export const ExportButton = ({
  children,
  disabled = false,
  showErrorMessage = false,
  onClick = () => {},
  className = "",
}) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={disabled ? () => {} : onClick}
      className={clsx(styles.exportBtn, className)}
    >
      <div className={styles.content}>{children}</div>
      <Tooltip
        title={showErrorMessage ? t("field.exportBookingsError") : ""}
        placement="topLeft"
        overlayClassName="hint-error"
        className="hint-error"
      >
        <ExportIcon
          className={clsx(styles.icon, disabled ? styles.disabled : "")}
        />
      </Tooltip>
    </button>
  );
};
