import { forwardRef } from "react";
import { Radio as Item } from "antd";
import clsx from "clsx";
import PropTypes from "prop-types";

import styles from "./Radio.module.scss";

export const Radio = forwardRef(function Radio(
  { className, children, ...otherProps },
  ref,
) {
  return (
    <Item
      className={clsx(styles.customRadioItem, className)}
      ref={ref}
      {...otherProps}
    >
      {children}
    </Item>
  );
});

Radio.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  otherProps: PropTypes.object,
};

Radio.defaultProps = {
  className: "",
  children: null,
};
