import { createSearchParams, useNavigate } from "react-router-dom";
import { CustomDropdown } from "pagesLegacy/components/CustomDropdown";
import { ReactComponent as Edit } from "assets/icons/editIcon.svg";
import styles from "./UserListItem.module.scss";

export const userActionOptions = [
  {
    id: 0,
    value: "edit",
    label: "Edit",
    icon: Edit,
  },
];

export const UserListItem = ({ user, isSuperAdmin }) => {
  const { id, name, email } = user;
  const navigate = useNavigate();

  return (
    <div className={styles.listItem}>
      <div className={styles.content}>
        <h3 className={styles.title}>{name}</h3>

        <p className={styles.text}>{email}</p>
      </div>

      {isSuperAdmin && (
        <CustomDropdown
          options={userActionOptions}
          onItemSelect={() =>
            navigate({
              pathname: `${id}`,
              search: createSearchParams({ id, name, email }).toString(),
            })
          }
        />
      )}
    </div>
  );
};
