import { Layout } from "antd";

import "./GeneralLayoutContent.scss";

const { Content } = Layout;

export function GeneralLayoutContent({ header, children }) {
  return (
    <Layout className="wallet-layout-wrapper">
      {header}

      <Layout className="content-wrapper wallet-content-wrapper">
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
}
