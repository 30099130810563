import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import SignaturePad from "signature_pad";

import { log } from "helpers/logger";
import { InputCanvas } from "../InputCanvas";
import { Button } from "../Button";
import { warningSnack } from "helpers/message";

const logInternal = (...args) => log("Signature.jsx", ...args);

export function Signature({
  value = "",
  onReset = () => undefined,
  onChange = (dataUrl) => undefined,
}) {
  const { t } = useTranslation();

  logInternal("component function body", "received props", { value });

  /**
   * @type {React.RefObject<HTMLCanvasElement | null>}
   */
  const canvasRef = useRef(null);
  /**
   * @type {React.RefObject<{} | null>}
   */
  const signaturePadRef = useRef(null);

  const onEndStroke = useCallback(
    function onEndStrokeInternal() {
      logInternal("onEndStroke", "started");

      if (signaturePadRef.current === null) return;

      const dataURL = signaturePadRef.current.toDataURL();
      logInternal(
        "onEndStroke",
        "Signature Pad is already initialized - now call `onChange` with",
        {
          dataURL,
        },
      );
      onChange(dataURL);
    },
    [onChange],
  );

  const resizeCanvas = useCallback(
    function resizeCanvasInternal() {
      logInternal("resizeCanvas", "started");

      if (signaturePadRef.current === null || canvasRef.current === null)
        return;

      logInternal(
        "resizeCanvas",
        "Signature Pad is already initialized - now resize",
      );
      const ratio = Math.max(window.devicePixelRatio || 1, 1);

      canvasRef.current.width = canvasRef.current.offsetWidth * ratio;
      canvasRef.current.height = canvasRef.current.offsetHeight * ratio;
      canvasRef.current.getContext("2d").scale(ratio, ratio);

      logInternal("resizeCanvas", "resized - now call `onReset`");
      onReset();
    },
    [onReset],
  );

  if (signaturePadRef.current === null && canvasRef.current !== null) {
    logInternal(
      "component function body",
      "Signature Pad is not initialized - initialization started",
    );

    signaturePadRef.current = new SignaturePad(canvasRef.current, {
      penColor: "rgb(0, 0, 0)",
      minWidth: 0.8,
      maxWidth: 1,
      minDistance: 3,
    });

    logInternal(
      "component function body",
      "Signature Pad is initialized - now add `onEndStroke` as `signaturePad.endStroke` event listener",
    );
    signaturePadRef.current.addEventListener("endStroke", onEndStroke);

    logInternal(
      "component function body",
      "endStroke event listener is added - now add `resizeCanvas` as `window.resize` event listener",
    );
    window.addEventListener("resize", resizeCanvas);

    logInternal(
      "component function body",
      "resize event listener is added - now schedule the `resizeCanvas` call in the event loop",
    );
    setTimeout(resizeCanvas, 0);
  }

  const cleanupTimerRef = useRef(null);

  useEffect(() => {
    logInternal("cleanup effect", "started");

    if (cleanupTimerRef.current !== null) {
      logInternal("cleanup effect", "cleanup timer exists - clear it");
      clearTimeout(cleanupTimerRef.current);
    }

    return () => {
      logInternal(
        "cleanup effect",
        "cleanup function: set timeout to cleanup only after unmount (which occurred without remounting)",
      );

      const WAIT_BEFORE_CLEANUP = 3000;
      cleanupTimerRef.current = setTimeout(() => {
        logInternal(
          "cleanup effect",
          "cleanup function: timeout: remove `resizeCanvas` from `window.resize` event listeners list",
        );
        window.removeEventListener("resize", resizeCanvas);

        logInternal(
          "cleanup effect",
          "cleanup function: timeout: set `signaturePadRef.current` to `null`",
        );
        signaturePadRef.current = null;
      }, WAIT_BEFORE_CLEANUP);
    };
  });

  useEffect(() => {
    logInternal("value changed effect", "started", { value });

    drawFromDataURL(value);
    return clearCanvas;
  }, [value]);

  function drawFromDataURL(dataURL) {
    logInternal("drawFromDataURL", "started with argument", { dataURL });

    if (signaturePadRef.current === null || dataURL === "") return;

    logInternal(
      "drawFromDataURL",
      "Signature Pad is initialized and dataURL is not empty - now call `signaturePad.fromDataURL(dataURL)`",
    );
    signaturePadRef.current.fromDataURL(dataURL);
  }

  function clearCanvas() {
    logInternal("clearCanvas", "started");

    if (signaturePadRef.current === null) return;

    logInternal("clearCanvas", "Signature Pad is initialized - now clear it");
    signaturePadRef.current.clear();
  }

  function saveAsPng() {
    logInternal("saveAsPng", "started");

    const signaturePad = signaturePadRef.current;
    const isEmpty = signaturePad.isEmpty();

    if (isEmpty) {
      warningSnack(t("error.canvasEmpty"));
      return;
    }

    const dataURL = signaturePad.toDataURL();
    download(dataURL, `SIGNATURE-${Date.now()}.png`);
  }

  return (
    <>
      <InputCanvas ref={canvasRef} />
      <div
        style={{
          display: "flex",
          gap: "12px",
        }}
      >
        <Button
          variant="link"
          importance="error"
          fontSize="sm"
          hideSpinner
          unsetLetterSpacing
          shape="round"
          onClick={onReset}
        >
          {t("action.clearCanvas")}
        </Button>
        <Button
          variant="link"
          fontSize="sm"
          shape="round"
          hideSpinner
          unsetLetterSpacing
          onClick={() => saveAsPng()}
        >
          {t("action.saveAsPng")}
        </Button>
      </div>
    </>
  );
}

function download(dataURL, filename) {
  const blob = dataURLToBlob(dataURL);
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.style = "display: none";
  a.href = url;
  a.download = filename;

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
}

// One could simply use Canvas#toBlob method instead, but it's just to show
// that it can be done using result of SignaturePad#toDataURL.
function dataURLToBlob(dataURL) {
  // Code taken from https://github.com/ebidel/filer.js
  const parts = dataURL.split(";base64,");
  const contentType = parts[0].split(":")[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
}
