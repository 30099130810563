import { message as antdMessage } from "antd";

export const {
  success: successSnack,
  error: errorSnack,
  warn: warnSnack,
  warning: warningSnack,
  info: infoSnack,
  destroy: destroySnack,
  loading: loadingSnack,
  useMessage,
  open,
  config,
} = antdMessage;
