import React from "react";
import { ReactComponent as BurgerIcon } from "assets/icons/burger_icon.svg";
import { CustomDropdown } from "pagesLegacy/components/CustomDropdown";
import {
  DifferenceTag,
  DifferenceTagType,
} from "components/DifferenceTag/DifferenceTag";
import { Link } from "react-router-dom";
import styles from "./DashboardItem.module.scss";

export const DashboardItem = ({
  testId,
  title,
  subtitle,
  amount,
  difference,
  link,
  linkText,
  className,
}) => {
  const isPositive = difference > 0;
  const tag = {
    text: difference
      ? `${isPositive ? difference : difference.toString().replace("-", "")}%`
      : "",
    type: isPositive ? DifferenceTagType.Positive : DifferenceTagType.Negative,
  };

  const amountTestId =
    typeof testId === "string" && testId.length > 0
      ? `${testId}-amount`
      : undefined;

  return (
    <div className={`${styles.dashboardItem} ${className}`}>
      <div className={styles.dashboardItem__header}>
        <BurgerIcon />
        <h3 className={styles.dashboardItem__title}>{title}</h3>
        <CustomDropdown />
      </div>

      <span className={styles.dashboardItem__amount} data-testid={amountTestId}>
        {amount}
      </span>

      {subtitle && (
        <h4 className={styles.dashboardItem__subtitle}>{subtitle}</h4>
      )}

      {difference && <DifferenceTag {...tag} />}

      {link && linkText && (
        <Link className={styles.dashboardItem__link} to={link}>
          {linkText}
        </Link>
      )}
    </div>
  );
};
