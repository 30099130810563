import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { useAuth } from "hooks/useAuth";
import { routes } from "routes";
import {
  useQueryCustomDocumentTypes,
  getLatestDocumentByStatusByType,
} from "pages/documents";
import { ReactComponent as Delimited } from "assets/icons/Sidebar/arrowDown.svg";
import { ReactComponent as CheckIcon } from "assets/icons/checkCurrentColor.svg";

import styles from "./Breadcrumbs.module.scss";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { useSelector } from "react-redux";

const STATUS_TYPE = {
  regular: "regular",
  completed: "completed",
};
const getStatusType = (isCompleted) =>
  isCompleted ? STATUS_TYPE.completed : STATUS_TYPE.regular;

export function Breadcrumbs({ spaceBetween = false }) {
  const { documentTypeId } = useParams();
  const { t } = useTranslation();
  const { vehicleApplicationFormData } = useSelector(
    (state) => state.addNewVehicleslice,
  );

  const { selectedFleetId: fleetId } = useSelectedFleet();
  const {
    predefinedVehicleDocumentTypes,
    customVehicleApplicationDocumentTypes,
  } = useQueryCustomDocumentTypes({ currentCompanyId: fleetId });

  return (
    <ul className={clsx(styles.list, { [styles.spaceBetween]: spaceBetween })}>
      {predefinedVehicleDocumentTypes.map((documentType, index) => {
        return (
          <li key={documentType.id} className={styles.item}>
            <Breadcrumb
              to={routes.addNewVehicle.getURL({
                documentTypeId: documentType.id,
              })}
              number={index + 1}
              current={documentTypeId === String(documentType.id)}
              status={getStatusType(
                Boolean(vehicleApplicationFormData[documentType.id]),
              )}
            >
              {documentType.name}
            </Breadcrumb>

            <Delimited className={styles.delimiter} />
          </li>
        );
      })}
      {customVehicleApplicationDocumentTypes.map((documentType, index) => {
        return (
          <li key={documentType.id} className={styles.item}>
            <Breadcrumb
              to={routes.addNewVehicle.getURL({
                documentTypeId: documentType.id,
              })}
              number={predefinedVehicleDocumentTypes.length + index + 1}
              current={documentTypeId === String(documentType.id)}
              status={getStatusType(
                Boolean(vehicleApplicationFormData[documentType.id]),
              )}
            >
              {documentType.name}
            </Breadcrumb>

            <Delimited className={styles.delimiter} />
          </li>
        );
      })}

      <li className={styles.item}>
        <Breadcrumb
          to={routes.addNewVehicle.getURL({
            documentTypeId: "submit",
          })}
          number={
            predefinedVehicleDocumentTypes.length +
            customVehicleApplicationDocumentTypes.length +
            1
          }
          current={documentTypeId === "submit"}
          status={STATUS_TYPE.regular}
        >
          {t("action.submit")}
        </Breadcrumb>
      </li>
    </ul>
  );
}

function Breadcrumb({
  to,
  children,
  number,
  current = false,
  restricted = false,
  status,
}) {
  if (restricted) {
    return (
      <div className={styles.breadcrumb}>
        <BreadcrumbCoin status={status} current={current}>
          {number}
        </BreadcrumbCoin>
        <div
          className={clsx(styles.breadcrumbText, { [styles.current]: current })}
        >
          {children}
        </div>
      </div>
    );
  }

  return (
    <Link to={to} className={styles.breadcrumb}>
      <BreadcrumbCoin status={status} current={current}>
        {number}
      </BreadcrumbCoin>
      <div
        className={clsx(styles.breadcrumbText, { [styles.current]: current })}
      >
        {children}
      </div>
    </Link>
  );
}

function BreadcrumbCoin({ status = STATUS_TYPE.regular, current, children }) {
  const regular = status === STATUS_TYPE.regular;
  const completed = status === STATUS_TYPE.completed;

  return (
    <div
      className={clsx(styles.coin, {
        [styles.regular]: regular,
        [styles.current]: current,
        [styles.completed]: completed,
      })}
    >
      {completed ? <CheckIcon className={styles.checkIcon} /> : null}
      {regular ? children : null}
    </div>
  );
}

export function useLatestDocumentByStatusByType() {
  const driverApplication = useCurrentDriverApplication();

  return getLatestDocumentByStatusByType(driverApplication?.documents);
}

export function useCurrentDriverApplication() {
  const { selectedFleetId: fleetId } = useSelectedFleet();

  const { user } = useAuth();

  return (user?.driver_applications ?? []).find(
    (application) => application.fleet_id === Number(fleetId),
  );
}
