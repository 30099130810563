import { forwardRef } from "react";
import { Select } from "./Select";
import currencies from "./currencies.json";

const AVAILABLE_ON_BACKEND_CURRENCY_CODES = [
  "EUR",
  "GBP",
  "USD",
  "CAD",
  "CHF",
  "AUD",
  "NZD",
  "GYD",
];
const AVAIlABLE_ON_BACKEND_CURRENCIES = currencies.filter((currency) =>
  AVAILABLE_ON_BACKEND_CURRENCY_CODES.includes(currency.cc),
);

export const SelectCurrency = forwardRef(function SelectCurrencyInternal(
  { limit = "none", ...otherProps },
  ref,
) {
  const options =
    limit === "availableOnBackend"
      ? AVAIlABLE_ON_BACKEND_CURRENCIES
      : currencies;

  return (
    <Select
      {...otherProps}
      ref={ref}
      options={options.map((currency) => ({
        value: currency.cc,
        children: `${currency.cc} – ${currency.name}`,
      }))}
    />
  );
});
