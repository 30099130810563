import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import {
  format,
  startOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

import { api, PayoutMethod, formatPayoutDateForAPI } from "api";
import { twoDecimals } from "helpers/twoDecimal";
import { useSelectedFleet } from "hooks/useSelectedFleet";

import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";

import { Spacer } from "components/Spacer";
import { LoadingComp } from "components/LoadingComp";
import { Button } from "components/Button";
import { DatePicker } from "components/DatePicker";
import { Field } from "components/Field";

import { WidgetContainer, WidgetLink, WidgetTitle } from "./Widget";
import { useBreakpoint } from "hooks/useBreakpoint";

const TODAY = startOfDay(new Date());

const REFETCH_DELAY = 3 * 1000;

export function PayoutsWidget({ schedulePayoutsRefetch = false } = {}) {
  const { t } = useTranslation();

  const { selectedDriverProfile } = useSelectedFleet();
  const [cursor, setCursor] = useState(null);
  const [payoutDateRange, setPayoutDateRange] = useState(null);
  const [payoutDateFrom, payoutDateTo] = payoutDateRange ?? [];

  const { data, isFetching, refetch } = api.endpoints.getPayouts.useQuery({
    driverProfileId: selectedDriverProfile.id,
    perPage: 5,
    cursor,
    payoutDateFrom: formatPayoutDateForAPI(payoutDateFrom),
    payoutDateTo: formatPayoutDateForAPI(payoutDateTo),
  });

  useEffect(
    function schedulePayoutsRefetchToReceiveTheLinkOfTheNewestPayout() {
      /**
       * Refetch the payouts with some delay to ensure that the newest
       * payout has attached "link" to the vat receipt.
       *
       * Regular cache key invalidation does not keep the UI up to date.
       * Reason: the "link" is attached to the newest payout AFTER
       * the request scheduled because of invalid cache key is resolved.
       */
      if (!schedulePayoutsRefetch) return;

      const timeout = setTimeout(refetch, REFETCH_DELAY);

      return () => {
        clearTimeout(timeout);
      };
    },
    [refetch, schedulePayoutsRefetch],
  );

  const { payoutData, nextCursor, prevCursor } = useMemo(() => {
    const payoutData = data?.data.map((payout) => ({
      key: payout.id,
      arrivalDate: payout.arrival_date,
      amount: payout.amount / 100,
      method: payout.method,
      link: payout.receipt,
    }));

    const nextCursor = data?.meta?.next_cursor;
    const prevCursor = data?.meta?.prev_cursor;

    return {
      payoutData,
      nextCursor,
      prevCursor,
    };
  }, [data]);

  const ranges = useMemo(() => {
    return {
      [t("componentRangePicker.today")]: [TODAY, TODAY],
      [t("componentRangePicker.thisWeek")]: [
        startOfWeek(TODAY),
        endOfWeek(TODAY),
      ],
      [t("componentRangePicker.thisMonth")]: [
        startOfMonth(TODAY),
        endOfMonth(TODAY),
      ],
    };
  }, [t]);

  const { xl } = useBreakpoint();
  const columns = useGetPayoutsTableColumns();

  return (
    <WidgetContainer>
      <WidgetTitle>{t("widgetPayoutList.payouts")}</WidgetTitle>
      <Field label={t("widgetPayoutList.filterByPayoutDate")}>
        <DatePicker
          isRange
          value={payoutDateRange}
          onChange={(payoutDateRange) => {
            setPayoutDateRange(
              Array.isArray(payoutDateRange)
                ? payoutDateRange.map((date) => startOfDay(date))
                : payoutDateRange, // payoutDateRange may be null
            );
            setCursor(null); // reset pagination after payoutDateRange changed
          }}
          ranges={ranges}
        />
      </Field>
      <Table
        loading={{
          indicator: <LoadingComp loading />,
          spinning: isFetching,
          size: "large",
        }}
        pagination={false}
        dataSource={payoutData}
        columns={columns}
        size={xl ? "middle" : "small"}
        bordered={false}
      />
      <Spacer />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 8,
        }}
      >
        <Button
          disabled={isFetching || prevCursor === null}
          shape="round"
          onClick={() => setCursor(prevCursor)}
        >
          {t("action.newer")}
        </Button>
        <Button
          disabled={isFetching || nextCursor === null}
          shape="round"
          onClick={() => setCursor(nextCursor)}
        >
          {t("action.older")}
        </Button>
      </div>
    </WidgetContainer>
  );
}

function useGetPayoutsTableColumns() {
  const { t } = useTranslation();
  const { selectedFleetCurrency } = useSelectedFleet();

  const payoutDateFormat = "do MMM, yyyy";
  const columns = [
    {
      title: t("widgetPayoutList.amount"),
      dataIndex: "amount",
      render(text) {
        return selectedFleetCurrency + twoDecimals(text);
      },
    },
    {
      title: t("widgetPayoutList.method"),
      dataIndex: "method",
      render(text) {
        return <MethodCell method={text} />;
      },
    },
    {
      title: t("widgetPayoutList.payoutDate"),
      dataIndex: "arrivalDate",
      render(text) {
        return format(new Date(text), payoutDateFormat);
      },
    },
    {
      title: t("widgetPayoutList.vatReceipt"),
      dataIndex: "link",
      render(text) {
        return <DownloadVatReceiptCell link={text} />;
      },
    },
  ];

  return columns;
}

function DownloadVatReceiptCell({ link }) {
  const { t } = useTranslation();
  const { xs, sm, md } = useBreakpoint();
  const isSmall = !md && (xs || sm);

  if (typeof link !== "string") {
    return null;
  }

  if (isSmall) {
    return (
      <WidgetLink
        href={link}
        target="_blank"
        rel="noreferrer nofollow"
        underlined={false}
        center={true}
      >
        <DownloadIcon style={{ width: 24, height: 24 }} />
      </WidgetLink>
    );
  }

  return (
    <WidgetLink href={link} target="_blank" rel="noreferrer nofollow">
      {t("action.download")}
    </WidgetLink>
  );
}

const payoutMethods = Object.values(PayoutMethod);
function MethodCell({ method }) {
  const { t } = useTranslation();

  if (payoutMethods.includes(method)) {
    return t(`payoutMethod.${method}`);
  }

  return "-";
}
