import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "components/Select";
import styles from "./StripeConfirmationModal.module.scss";

export const SelectAnotherCompany = ({
  onFleetChange,
  selectedFleet,
  allFleets,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.selectAnotherCompanyWrapper}>
      <h3 className={styles.selectAnotherProfile}>
        {t("componentSelectAnotherFleet.selectAnotherProfile")}
      </h3>
      <Select
        onChange={onFleetChange}
        value={selectedFleet.id}
        popupClassName={styles.confirmationModalSelect}
        options={allFleets.map(({ id, name }) => ({
          value: id,
          children: name,
        }))}
      />
    </div>
  );
};
